
























































































































import Vue from "vue";
import VarDates from "@/components/Variables/VariableDates.vue";
import VarWCategories from "@/components/Variables/VariableWCategories.vue";
import VarManufacturers from "@/components/Variables/VariableManufacturers.vue";
import VarPrices from "@/components/Variables/VariablePrices.vue";
import VarEngineCc from "@/components/Variables/VariableCc.vue";
import Tab from "@/components/Variables/Tab.vue";
import ShowNullSwitch from "@/components/Variables/ShowNullSwitch/index.vue";
import { StoreQueryType } from "@/assets/types/StoreQueryType";
import { deepClone } from "@/js/utils";
import { variableTitle } from "@/js/const"

export default Vue.extend({
  name: "MultiLineChartQuery",
  components: {
    VarDates,
    VarWCategories,
    VarManufacturers,
    VarPrices,
    VarEngineCc,
    Tab,
    ShowNullSwitch,
  },
  data: () => ({
    tab: null,
    localQuery1: {} as StoreQueryType,
    localQuery2: {} as StoreQueryType,
  }),
  props: {
    show: { type: Boolean, default: false },
    // これは直接操作しない
    query1: { type: Object, required: true },
    query2: { type: Object, required: true },
  },
  watch: {
    show() {
      if (this.show) {
        this.copyQuery1();
        this.copyQuery2();
      }
    },
    query1() {
      this.copyQuery1();
    },
    query2() {
      this.copyQuery2();
    },
  },
  mounted() {
    this.copyQuery1();
    this.copyQuery2();
  },
  computed: {
    variables(): readonly any[]{
      return variableTitle;
    },
    showMonth: {
      get(): boolean {
        return this.$store.getters.showMonth;
      },
      set(val: boolean) {
        this.$store.dispatch("updateShowMonth", val);
      },
    },

    queryDateFormat(): string {
      return this.showMonth ? "month" : "year";
    },
  },
  methods: {
    close() {
      this.$emit("update:show", false);
    },
    copyQuery1() {
      this.localQuery1 = deepClone(this.query1);
    },
    copyQuery2() {
      this.localQuery2 = deepClone(this.query2);
    },
    sync() {
      this.localQuery2 = deepClone(this.localQuery1);
    },
    search() {
      this.$emit("update:query1", this.localQuery1);
      this.$emit("update:query2", this.localQuery2);
      this.close()
    },
  },
});
