






import Vue from "vue";
export default Vue.extend({
  props: { icon: String, text: String },
});
