var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_vm._v(_vm._s(_vm.data[0].name))]),_vm._l((_vm.sorted),function(p,i){return _c('div',{key:i},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',{style:({ 'padding-right': '10px', width: '100%' })},[(p.seriesName === '台数')?_c('div',{style:({
            display: 'flex',
            'justify-content': 'space-between',
            width: '100%'
          })},[_c('div',{domProps:{"innerHTML":_vm._s(p.marker)}}),_c('div',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(p.value.toLocaleString())+" 台")])]):_c('div',{style:({
            display: 'flex',
            'justify-content': 'space-between',
            width: '100%'
          })},[_c('div',{style:({ display: 'flex' })},[_c('div',{domProps:{"innerHTML":_vm._s(p.marker)}}),_vm._v(" "+_vm._s(p.seriesName)+": ")]),_c('div',{style:({ 'font-weight': 'bold', 'padding-left': '10px' })},[_vm._v(" "+_vm._s(Math.round(Number(p.value[1])).toLocaleString())+" 台 ")])])])])])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }