<template>
  <v-row class="mx-3">
    <v-col cols="3">
      <v-select
        :items="wCategories"
        :value="selectedWCategory"
        @change="$emit('update:selectedWCategory', $event)"
        filled
        outlined
        clearable
        label="wCategory"
      ></v-select
      ><br />
      <v-select
        :items="looks"
        :value="selectedLooks"
        @change="$emit('update:selectedLooks', $event)"
        filled
        outlined
        clearable
        label="単位変更"
      ></v-select>
      <v-slider
        label="OTHERS率"
        :min="0"
        :max="100"
        thumb-label
        :value="rate_others"
        @change="$emit('update:rate_others', $event)"
      ></v-slider>
    </v-col>
    <v-col cols="3">
      <v-select
        :items="makers"
        :value="selectedMaker"
        @change="$emit('update:selectedMaker', $event)"
        filled
        outlined
        clearable
        label="Maker"
      ></v-select>
    </v-col>
    <v-col cols="3">
      <v-switch
        :disabled="isFuture"
        :input-value="isYear"
        :label="isYear ? '年単位' : '月単位'"
        @change="$emit('update:isYear', $event ? $event : false)"
      ></v-switch>
      <v-switch
        :input-value="showLabel"
        :label="showLabel ? 'ラベル表示' : 'ラベル非表示'"
        @change="$emit('update:showLabel', $event ? $event : false)"
      ></v-switch
      ><br />
      <p class="text-left">
        ラベルフォントサイズ
        <v-btn
          class="mx-2"
          fab
          dark
          small
          @click="updateLabelFontSize(labelFontSize - 1)"
        >
          <v-icon dark> mdi-minus </v-icon>
        </v-btn>
        <v-btn
          class="mx-2"
          fab
          dark
          small
          @click="updateLabelFontSize(labelFontSize + 1)"
        >
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </p>
      <p class="text-left">
        軸フォントサイズ
        <v-btn
          class="mx-2"
          fab
          dark
          small
          @click="updateAxisFontSize(axisFontSize - 1)"
        >
          <v-icon dark> mdi-minus </v-icon>
        </v-btn>
        <v-btn
          class="mx-2"
          fab
          dark
          small
          @click="updateAxisFontSize(axisFontSize + 1)"
        >
          <v-icon dark> mdi-plus </v-icon>
        </v-btn>
      </p>
    </v-col>
    <v-col cols="3">
      <v-switch
        :input-value="showPiechart"
        :label="showPiechart ? '円グラフ表示' : '円グラフ非表示'"
        @change="$emit('update:showPiechart', $event ? $event : false)"
      ></v-switch>
      並び替え<br />
      <chart-sort :list="labelAndColor" @sort-change="setSort"> </chart-sort>
    </v-col>
  </v-row>
</template>
<script>
import ChartSort from "@/components/BarChart/Sort";
import { getKeys } from "@/js/utils";
export default {
  name: "Params",
  components: {
    ChartSort,
  },
  props: {
    data: {
      type: Array,
    },
    selectedMaker: {},
    selectedWCategory: {},
    selectedLooks: {},
    rate_others: {
      type: Number,
    },
    showLabel: {
      type: Boolean,
    },
    showPiechart: {
      type: Boolean,
    },
    isYear: {
      type: Boolean,
    },
    labelAndColor: {
      type: Array,
    },
    isFuture: {
      type: Boolean,
    },
  },
  computed: {
    category() {
      return this.isFuture ? "f_category" : "w_category";
    },
    looks() {
      return ["maker", "model"];
    },
    makers() {
      return getKeys(this.data, "maker");
    },
    wCategories() {
      return getKeys(this.data, this.category);
    },
    labelFontSize() {
      return this.$store.getters["viewSettings/labelFontSize"];
    },
    axisFontSize() {
      return this.$store.getters["viewSettings/axisFontSize"];
    },
  },
  methods: {
    updateLabelFontSize(value) {
      this.$store.dispatch("viewSettings/updateLabelFontSize", value);
    },
    updateAxisFontSize(value) {
      this.$store.dispatch("viewSettings/updateAxisFontSize", value);
    },
    setSort(arg) {
      this.$emit("sort-change", arg);
    },
  },
};
</script>
