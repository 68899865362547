<template>
  <validation-provider :name="label" :rules="rules" v-slot="{ errors, valid }">
    <v-text-field
      :type="type"
      :label="label"
      filled
      v-model.trim="inputValue"
      :placeholder="placeholder"
      :hint="hint"
      clearable
      :error-messages="errors"
      :success="valid"
      :suffix="suffix"
      outlined
    />
  </validation-provider>
</template>
<script>
export default {
  name: "ValidationText",
  props: {
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "text",
    },
    val: {
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    hint: {
      type: String,
      default: "",
    },
    rules: {
      default: "required",
    },
    suffix: {
      default: undefined,
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.val;
      },
      set(value) {
        this.$emit("update:val", value);
      },
    },
  },
};
</script>