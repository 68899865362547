


























































import { Data } from "@/assets/types/Data";
import { Config } from "@/store/tableGraph";
import Vue from "vue";
import TableGraph from "@/components/TableGraph/Index.vue";

export default Vue.extend({
  name: "ActionableTableGraph",
  components: { TableGraph },
  data() {
    return {
      tempCheckEuro: false,
      modal: false,
      isLoading: false,
      contextItem: null,
      contextPosition: { x: 0, y: 0 },
    };
  },
  computed: {
    tableGraphData(): Data[] {
      return this.$store.getters["tableGraph/filteredGraphData"];
    },

    config(): Config {
      return this.$store.getters["tableGraph/config"];
    },

    viewMode(): string {
      return this.config.viewMode;
    },

    filterModes(): any[] {
      const modes = [
        { value: "maker", text: "メーカー別" },
        { value: "w_category", text: "カテゴリー別" },
        { value: "model", text: "モデル別" },
      ];
      const excludes = [this.viewMode];

      if (this.config.query.w_category) {
        excludes.push("w_category");
      }

      if (this.config.query.maker) {
        excludes.push("maker");
      }

      const viewModes = modes.filter(({ value }) => !excludes.includes(value));
      return viewModes;
    },
  },
  methods: {
    showContext({ event, item }) {
      this.contextItem = item;
      const { x, y } = event as MouseEvent;
      this.contextPosition = { x, y };
    },
    clickOutSelect() {
      this.contextItem = null;
    },

    async changeCheckedEuro(value) {
      await this.$store.dispatch("tableGraph/withoutEuro", !value);
      this.modal = false;
    },

    async refreshData() {
      await this.$store.dispatch("tableGraph/resetConfig");
    },

    onDoubleClick(item) {
      if (this.viewMode !== "model") {
        if (this.viewMode === "maker") {
          this.openChild(item, "model");
        } else {
          this.openChild(item, "maker");
        }
      }
    },

    async openChild(item, mode) {
      // モード切り替え
      const { name } = item;
      const { config } = this;
      const query = { ...config.query, [this.viewMode]: name };
      const newConfig: Config = { viewMode: mode, query };
      await this.$store.dispatch("tableGraph/setConfig", newConfig);
    },
  },
});
