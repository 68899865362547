import { Data } from "@/assets/types/Data";

export const downloadCsv = (array: (string | number)[][], name: string = "download") => {
  // 確認用
  // console.log(array[1])
  // return
  const csv = arrayToCsv(array);
  var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.download = name + ".csv";
  a.href = url;
  a.click();
  a.remove();
  URL.revokeObjectURL(url);
};

const arrayToCsv = (array: (string | number)[][]) => {
  const rows = array.map((arr) => arr.join("\t"));
  const csv = rows.join("\n");
  return csv;
};

export const dataToArray = (datas: Data[]) => {
  const columns: (keyof Data | typeof Data.Months[number])[] = [
    "id",
    "country",
    "year",
    "maker",
    "model",
    "cc",
    "w_category",
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",
    "price",
  ];
  return [columns, ...datas.map((v) => columns.map((key) => v[key]))];
};
