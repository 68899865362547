var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('resizable',{staticClass:"container"},[_c('div',{style:({ display: 'flex', width: '100%', height: '100%' })},[_c('div',{style:({
        display: 'flex',
        width: '100%',
        height: '100%',
        'flex-direction': 'column',
      })},[_c('v-chart',{ref:"chart",style:({ width: '90%', height: '100%' }),attrs:{"option":_vm.baseOption,"autoresize":true}}),_c('v-select',{attrs:{"items":_vm.continuousYears,"dense":""},model:{value:(_vm.selectedYear),callback:function ($$v) {_vm.selectedYear=$$v},expression:"selectedYear"}}),_c('v-slider',{attrs:{"label":"円の大きさ","value":_vm.symbolSizeFactor,"min":0.1,"max":3,"step":0.01},on:{"input":_vm.updateSymbolSizeFactor}}),_c('v-range-slider',{attrs:{"label":"台数フィルタ","value":_vm.valueFilter,"min":_vm.minValue,"max":_vm.maxValue,"step":1,"thumb-label":"always"},on:{"input":_vm.updateValueFilter}})],1),_c('div',{style:({
        display: 'flex',
        'flex-direction': 'column',
        'max-width': '10%',
      })},[_c('v-switch',{attrs:{"value":_vm.isLabelDraggable,"label":"ラベルをドラッグできるようにする(背景がドラッグできなくなります)"},on:{"change":_vm.updateIsLabelDraggable}}),_c('v-btn',{on:{"click":_vm.downloadCsv}},[_vm._v("DL Data")]),_c('v-btn',{on:{"click":_vm.downloadGraph}},[_vm._v("DL Graph")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }