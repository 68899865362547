<template>
  <v-menu
    :value="show"
    @input="$emit('update:show', $event)"
    absolute
    :position-x="x"
    :position-y="y"
    style="max-width: 600px"
  >
    <v-list>
      <v-list-item
        v-for="(item, index) in items"
        :key="index"
        @click="openLink(item)"
      >
        <v-list-item-title>{{
          item.modelyear + " " + item.nickname
        }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import axios from "axios";
import csv from "csvtojson";
export default {
  data: () => ({
    data: [],
  }),
  props: {
    show: {
      type: Boolean,
    },
    x: { type: Number },
    y: { type: Number },
    items: {
      type: Array,
    },
  },
  methods: {
    async openLink(item) {
      await this.getData();
      this.loadLink(item);
    },
    async getData() {
      const save = window.localStorage.getItem("timestamp");
      if (save) {
        if (new Date().getTime() - 3250000 < save) {
          this.data = window.localStorage.getItem("data");
          return;
        }
      }
      await this.fetchData();
    },
    async fetchData() {
      const data = await axios
        .get("https://cova.sakuraweb.com/data/data.php")
        .then();
      this.data = data.data;
      window.localStorage.setItem("data", this.data);
      window.localStorage.setItem("timestamp", new Date().getTime());
    },
    loadLink(item) {
      csv({ delimiter: "\t" })
        .fromString(this.data)
        .then((csv) => {
          const that = csv.find((e) => e.id === item.id);
          if (!that) {
            return;
          }
          const relationlinks = csv.filter(
            (e) =>
              e.modelgroup === that.modelgroup &&
              e.destination === that.destination
          );
          window.localStorage.setItem(
            "relation_" + that.id,
            JSON.stringify(relationlinks)
          );
          const resolvedRoute = this.$router.resolve({
            name: "ModelClass",
            query: { id: that.id, r_id: that.id },
          });
          window.open(resolvedRoute.href);
        });
    },
  },
};
</script>