





















































































import Vue from "vue";
import BaseNumberFormatInput from "../Base/BaseNumberFormatInput.vue";
import { createNamespacedHelpers, mapMutations } from "vuex";
import { Data } from "@/assets/types/Data";
const { mapMutations: graphMutations } = createNamespacedHelpers("tableGraph");
export default Vue.extend({
  props: {
    modelName: { type: String }, // モデル名
    manufacturers: { type: Array },
    wCategories: { type: Array },
  },
  components: {
    BaseNumberFormatInput,
  },
  watch: {
    modelName() {
      this.tempValues = { ...this.editingModelItem };
    },
  },
  mounted() {
    this.tempValues = { ...this.editingModelItem };
  },
  data() {
    return {
      tempValues: {
        maker: null,
        cc: null,
        price: null,
        w_category: null,
        category: null,
      } as Partial<Data>,
    };
  },
  computed: {
    editingModelItem(): Data {
      const datas = this.$store.state.data as Data[];
      const data = datas.find((d) => d.model === this.modelName);
      return data;
    },
  },
  methods: {
    ...mapMutations({
      updateGraphModel: "updateModelData",
    }),
    ...graphMutations({
      updateGraphModelHistory: "updateModelEditHistory",
    }),
    applyModelEdit(data: Data) {
      const { w_category, maker, cc, price } = data;
      const newModel = { w_category, maker, cc, price };
      const origin = { ...this.editingModelItem };
      const { modelName } = this;
      this.updateGraphModel(newModel);
      this.updateGraphModelHistory({ modelName, origin, newModel });
      this.$emit("update:modelName", null);
    },
  },
});
