




































import Vue, { PropOptions } from 'vue'
import AppVariableModal from '../Common/AppVariableModal.vue'
import BaseCheckboxes from '@/components/Base/BaseCheckboxes.vue'
import CcEvRadioGroup from '@/components/Variables/CcEvRadioGroup.vue'

export default Vue.extend({
  name: 'VariableCc',
  components: {
    AppVariableModal,
    BaseCheckboxes,
    CcEvRadioGroup,
  },

  props: {
    value: { type: Array, required: true } as PropOptions<string[]>,
    showCc: { type: Boolean, required: true },
    showEv: { type: Boolean, required: true },
  },

  computed: {
    options(): string[] {
      return this.$store.getters['evs']
    },

    selected: {
      get(): string[] {
        return this.value
      },

      set(val: string[]) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    clear() {
      this.selected = []
    },
    reset() {
      this.selected = this.options
    },
  },
})
