










import Vue, { PropOptions } from "vue";
import draggable from "vuedraggable";
export default Vue.extend({
  components: { draggable },
  props: {
    keyAndColorPair: { type: Array, required: true } as PropOptions<{ key: string; color: string }[]>,
    sorted: { type: Array, required: true } as PropOptions<string[]>,
  },
  computed: {
    list: {
      get(): string[] {
        return this.sorted.map((m) => m).reverse();
      },
      set(value: string[]) {
        const newValue = value.map((m) => m).reverse();
        this.$emit("update:sorted", newValue);
      },
    },
    colors(): { [key: string]: string } {
      return this.keyAndColorPair.map(({ key, color }) => ({ [key]: color })).reduce((p, c) => ({ ...p, ...c }), {});
    },
  },
});
