





import Vue, { PropOptions } from "vue";
export default Vue.extend({
  props: {
    value: { type: String, required: true },
    colors: { type: Object, required: true } as PropOptions<{ [key: string]: string }>,
  },
  computed: {
    color(): string {
      const { colors, value } = this;
      return colors[value] ? colors[value] : "primary";
    },
  },
});
