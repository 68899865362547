


























import Vue, { PropOptions } from 'vue'
export default Vue.extend({
  props: {
    data: { type: Array } as PropOptions<any[]>,
  },
  computed: {
    sorted(): Array<any> {
      return this.data.map((v) => v).filter((v) => v.value[1] > 0)
    },
  },
})
