
























import Vue, { PropOptions } from "vue";
import { SortType } from "@/js/sortDataKey";
import AppVariableModal from "../Common/AppVariableModal.vue";
import SortBox from "./Sort/SortBox.vue";
import CheckboxWithColorPalette from "./CheckboxWithColorPalette.vue";
import { RESET_TEMP_COLORS } from "@/store/modules/colors";

const key = "maker";
export default Vue.extend({
  name: "VariableManufacturers",
  components: { AppVariableModal, SortBox, CheckboxWithColorPalette },

  props: {
    value: { type: Array, required: true } as PropOptions<string[]>,
  },
  methods: {
    updateColor({ itemName, value }) {
      const color = { [itemName]: value };
      this.$store.dispatch("colors/setTempColor", { key, color });
    },
    resetColors() {
      this.$store.dispatch("colors/" + RESET_TEMP_COLORS, { key });
    },
  },

  computed: {
    colors(): { [key: string]: string } {
      return this.$store.getters["colors/tempMakers"];
    },
    colorHasChanged(): boolean {
      return this.$store.getters["colors/makerColorHasChanged"];
    },

    options(): string[] {
      return this.$store.getters.sortedManufacturers;
    },

    sortType: {
      get(): SortType {
        return this.$store.state.makerSortType;
      },
      set(sortType: SortType) {
        this.$store.commit("updateMakerSortType", sortType);
      },
    },

    selected: {
      get(): string[] {
        return this.value;
      },
      set(val: string[]) {
        this.$emit("input", val);
      },
    },
  },
});
