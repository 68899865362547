
















































import Vue, { PropOptions } from 'vue'
import AppVariableModal from '../Common/AppVariableModal.vue'
import { passiveWhenValid } from '@/plugins/vee-validate'
import BaseCheckboxes from '@/components/Base/BaseCheckboxes.vue'

// これに依存するのは良くないのでどうにかしないといけない
import { countries, euCountries } from '@/js/const'
import { deepEqual } from '@/js/utils'

export default Vue.extend({
  name: 'VariableCountries',
  components: {
    AppVariableModal,
    BaseCheckboxes,
  },

  props: {
    countries: Array,
    value: { type: Array, required: true } as PropOptions<string[]>,
  },

  data() {
    return {
      checkboxLabel: '地域',
      checkboxValidationMode: passiveWhenValid,
      selectedEuroCountries: [],
    }
  },
  watch: {
    // 毎回watchでemitするよりはまだ親コンポーネントが直接データを取りに行くほうが良いかもしれない。
    selectedEuroCountries(value) {
      if (!deepEqual(this.value, value)) {
        this.$emit('input', value)
      }
    },
    value() {
      if (this.value.some((v) => euCountries.find((counry) => counry.value === v))) {
        this.selectedEuroCountries = [...this.value]
      }
    },
  },
  mounted() {
    if (this.value.some((v) => euCountries.find((counry) => counry.value === v))) {
      this.selectedEuroCountries = [...this.value]
    }
  },

  computed: {
    euCountries() {
      return euCountries
    },
    isFuture(): boolean {
      return this.$store.getters.isFuture
    },

    areas(): (typeof countries[number]['value'])[] {
      return [...countries.map((v) => v.value)]
    },
    selectedAreas: {
      get(): string[] {
        return this.value
      },
      set(value: string[]) {
        if (!deepEqual(this.value, value)) {
          console.log(value)
          this.$emit('input', value)
        }
      },
    },
    selectedArea: {
      get(): typeof countries[number]['value'] {
        if (this.value.length === 1) {
          const selectedCountry = this.value[0]
          const notEuCountries = countries.map((v) => v.value)
          // eslint-disable-next-line no-inner-declarations
          function temp(v: string): v is typeof countries[number]['value'] {
            return notEuCountries.includes(v as any)
          }
          return temp(selectedCountry) ? selectedCountry : 'EUR'
        } else {
          return 'EUR'
        }
      },
      set(area: typeof countries[number]['text'] | 'EUR') {
        var selectedEuroCountries = this.selectedEuroCountries
        let value = area === 'EUR' ? selectedEuroCountries : [area]
        if (!deepEqual(this.value, value)) {
          console.log(value)
          this.$emit('input', value)
        }
      },
    },
  },
})
