<template>
  <app-variable-modal ref="obs" @reset-btn-click="reset" :show-reset="isChange">
    <v-row class="mt-2">
      <v-col class="col-12">
        <div id="upper-label">期間を選択してください。</div>
      </v-col>
    </v-row>
    <v-card-text>
      <validation-date
        label="開始月"
        v-model="start"
        :min-date="min"
        :max-date="startMax"
        :type="type"
      ></validation-date>
      <validation-date label="終了月" v-model="end" :min-date="endMin" :max-date="max" :type="type"></validation-date>
    </v-card-text>
  </app-variable-modal>
</template>

<script>
import AppVariableModal from "../Common/AppVariableModal.vue";
import moment from "moment";
import ValidationDate from "@/components/Validation/ValidationDatePicker";
export default {
  name: "VariableDates",
  data: () => ({
    isChange: false,
  }),
  components: {
    AppVariableModal,
    ValidationDate,
  },
  props: {
    value: { type: Array, required: true },
    type: { type: String, default: "month" },
  },
  watch: {
    type() {
      if (this.type === "year") {
        const start = moment(this.start);
        const end = moment(this.end);
        start.set("month", 0);
        end.set("month", 11);
        this.$emit("input", [start.format("YYYY-MM"), end.format("YYYY-MM")]);
      }
    },
  },
  computed: {
    min() {
      return this.$store.getters.dates[0];
    },
    max() {
      return this.$store.getters.dates[1];
    },
    start: {
      get() {
        return this.value[0].toString(); // fix when value comes in is a number
      },
      set(value) {
        this.$emit("input", [value, this.end]);
        this.isChange = true;
      },
    },
    end: {
      get() {
        return this.value[1].toString();
      },
      set(value) {
        this.$emit("input", [this.start, value]);
        this.isChange = true;
      },
    },
    startMax() {
      if (!this.end) {
        return this.max;
      }
      let end = moment(this.end),
        max = moment(this.max);
      if (end.isBefore(max)) {
        return this.end;
      }
      return this.max;
    },
    endMin() {
      if (!this.start) {
        return this.min;
      }
      let start = moment(this.start),
        min = moment(this.min);
      if (start.isAfter(min)) {
        return this.start;
      }
      return this.min;
    },
  },
  methods: {
    reset() {
      this.$emit("input", [this.min, this.max]);
      this.isChange = false;
    },
  },
};
</script>
