<template>
  <v-btn
    text
    @click="$emit('reset-btn-click')"
    v-show="isShow"
    :class="btnClasses"
    :disabled="btnDisabled"
    class="blue--text"
  >
    検索条件をリセットする
    <v-icon>mdi-reload</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "BaseResetButton",
  props: {
    btnDisabled: {
      type: Boolean,
      default: false,
    },
    isShow: {
      type: Boolean,
      default: false,
    },
    btnClasses: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

            