



import Vue, { PropOptions } from "vue";

export default Vue.extend({
  props: {
    filterBy: { type: Array } as PropOptions<{ type: string; values: string[] }[]>,
  },
  computed: {
    message(): string {
      const { filterBy } = this;
      const format = (values: string[], strong: boolean) => {
        let str = "";
        if (strong) {
          str += "<strong>";
        }
        str += values.join(", ");
        if (strong) {
          str += "</strong>";
        }
        return str;
      };
      const filters = filterBy
        .filter((v) => v.values.length > 0)
        .map((v, i) => Object.assign(v, { strong: i === 0 }))
        .map((v) => `${v.type} (${format(v.values, v.strong)})`);

      return `Filter by ${filters.join(" and ")}.`;
    },
  },
});
