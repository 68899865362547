









































import Vue, { PropOptions } from "vue";
import { passiveWhenValid } from "@/plugins/vee-validate";
import BaseCheckboxes from "@/components/Base/BaseCheckboxes.vue";
import ValidationCheckbox from "@/components/Validation/ValidationCheckbox.vue";
import ColorPalette from "./ColorPalette.vue";
import { pickerColors } from "@/js/const";
import $ from "jquery";
import moveable from "@/mixins/moveable";

export default Vue.extend({
  mixins: [moveable],
  props: {
    label: String,
    value: { type: Array, required: true } as PropOptions<string[]>,
    colors: Object,
    options: { type: Array, required: true } as PropOptions<string[]>,
  },
  components: {
    BaseCheckboxes,
    ValidationCheckbox,
    ColorPalette,
  },
  data() {
    return {
      checkboxValidationMode: passiveWhenValid,
      colorChangingItemName: null,
      pickerColors,
    };
  },
  methods: {
    updateColor(itemName, value) {
      if (itemName && value) {
        this.$emit("update:color", { itemName, value: value.hex ?? value });
      }
    },
    async toggleContext({ $event, value }) {
      this.colorChangingItemName = null;
      await this.$nextTick();
      $event.preventDefault();
      let mousePosition = (this as any).handleMouseMove($event);
      this.colorChangingItemName = value;
      await this.$nextTick();
      const top = mousePosition.y - 200 + "px";
      const left = mousePosition.x - 330 + "px";
      $(".v-menu__content.menuable__content__active").css({ top, left });
    },
  },
});
