





























































import Vue from "vue";
export default Vue.extend({
  props: {
    show: { type: Boolean, required: true },
  },
  mounted() {
    this.fetch();
  },
  watch: {
    show: "fetch",
  },
  data() {
    return {
      selectedId: 0,
      showTableGraph: false,
      // labelFontSize: 0,
      // axisFontSize: 0,
    };
  },
  computed: {
    localShow: {
      get(): boolean {
        return this.show;
      },
      set(value: boolean) {
        this.$emit("update:show", value);
      },
    },
    units(): { name: string; id: number }[] {
      return this.$store.getters["viewSettings/units"];
    },
    labelFontSize: {
      get(): number {
        return this.$store.getters["viewSettings/labelFontSize"];
      },
      set(value: number) {
        this.$store.dispatch("viewSettings/updateLabelFontSize", value);
      },
    },
    axisFontSize: {
      get(): number {
        return this.$store.getters["viewSettings/axisFontSize"];
      },
      set(value: number) {
        this.$store.dispatch("viewSettings/updateAxisFontSize", value);
      },
    },
  },

  methods: {
    fetch() {
      this.selectedId = this.$store.getters["viewSettings/selectedUnit"].id;
      this.showTableGraph = this.$store.getters["viewSettings/showTableGraph"];
    },
    select() {
      this.$store.dispatch("viewSettings/selectUnit", this.selectedId);
      this.$store.dispatch("viewSettings/updateShowTableGraph", this.showTableGraph);
      this.close();
    },
    close() {
      this.localShow = false;
    },
  },
});
