import Vue from 'vue'
export default Vue.extend({
  data() {
    return {
      moveable: {
      container: this.$el,
        edge: true,
        draggable: true,
        throttleDrag: 0,
        resizable: true,
        throttleResize: 0,
        keepRatio: false,
        scalable: false,
        throttleScale: 0,
        rotatable: false,
        throttleRotate: 0,
      },
    }
  },
  async mounted() {
    await this.$nextTick()
    // comment cause unusable anywhere
    // this.updateMaxValue()
  },
  watch: {
    keys() {
      this.selectedModels = null
      // comment cause unusable anywhere
      // this.updateMaxValue()
    },
  },

  methods: {
    handleDrag({ target, left, top }) {
      target.style.left = `${left}px`
      target.style.top = `${top}px`
    },
    handleResize(e) {
      const { target, width, height, delta } = e
      delta[0] && (target.style.width = `${width}px`)
      delta[1] && (target.style.height = `${height}px`)
    },
    handleMouseMove(event) {
      let eventDoc, doc, body;
      event = event || window.event;
      if (event.pageX == null && event.clientX != null) {
        eventDoc = (event.target && event.target.ownerDocument) || document;
        doc = eventDoc.documentElement;
        body = eventDoc.body;

        event.pageX =
            event.clientX +
            ((doc && doc.scrollLeft) || (body && body.scrollLeft) || 0) -
            ((doc && doc.clientLeft) || (body && body.clientLeft) || 0);
        event.pageY =
            event.clientY +
            ((doc && doc.scrollTop) || (body && body.scrollTop) || 0) -
            ((doc && doc.clientTop) || (body && body.clientTop) || 0);
      }
      return { x: event.pageX, y: event.pageY };
    },
  },
})
