






































import Vue, { PropOptions } from "vue";
export default Vue.extend({
  props: {
    data: { type: Array } as PropOptions<any[]>,
  },
  computed: {
    sorted(): Array<any> {
      return this.data.map((v) => v).sort((a, b) => b.value[1] - a.value[1]);
    },
  },
});
