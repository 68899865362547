





































































































































import Vue, { PropOptions } from 'vue'
import $ from 'jquery'
import * as d3 from 'd3'
import 'jquery-sparkline'
import { debounce } from '@/js/utils'
import moment from 'moment'
import moveable from '@/mixins/moveable'
import { pickerColors } from '@/js/const'
import { Data } from '@/assets/types/Data'

type RowData = {
  // カテゴリ名等
  name: string
  // nameをformatしたもの
  id: string
  // 指定年の合計
  sumYear?: number
  // toに指定した年月の台数
  month?: number
  // toに指定した年月の昨年の台数
  monthOfLastYear?: number
  // 上記台数の変化率(?)
  yoy?: number
  // sumYear / 全体のsumYear
  share?: number
  // Transitiveを作るもの
  rollupByDate?: Map<string, number>
}

type Transitive = {
  // 指定年月から過去二年分の配列
  bar: number[]
  // 指定年月から過去三年分の累積(表示は二年分のみ)
  redLine: number[]
  // 指定年月から過去二年分の累積平均
  greenLine: number[]
}

const dateFormat = 'YYYY-MM'
export default Vue.extend({
  name: 'TableGraph',
  mixins: [moveable],
  props: {
    itemsPerPage: { type: Number, default: 5 },
    itemSortOrder: { type: Object, default: () => ({}) } as PropOptions<{ [key: string]: number }>,
    tableGraphData: { type: [Array, Object], default: () => [] } as PropOptions<Data[]>,
    graphIdPrefix: { type: String, default: 'total' },
    group: { type: String, default: 'country' } as PropOptions<
      'maker' | 'model' | 'w_category' | 'country'
    >,
    searchable: { type: Boolean, default: false },
    color: { type: String, default: '78, 109, 160' },
    selected: { type: Array, default: () => [] },
    latestDate: { type: String, default: '2021-12' },
    showEmpty: { type: Boolean, default: true },
    rowEditable: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },

    disabledChangeColor: Boolean,
    colors: { type: Object },
    showChangeColor: { type: Boolean, default: false },
    hasChangedColor: { type: Boolean, default: false },

    showDetail: { type: Boolean, default: true },
  },
  data() {
    return {
      pickerColors,
      keyword: '',
      tempKeyword: '',
      options: { page: 1, sortBy: [] },
      colorChanging: null,
    }
  },
  computed: {
    latestYear(): number {
      return Number(this.latestDate.split('-')[0])
    },
    latestMonth(): number {
      return Number(this.latestDate.split('-')[1])
    },
    headers(): any[] {
      const { keyword } = this
      const array = [
        { show: Boolean(this.colors), text: '', align: 'start', value: 'checkbox',sortable:false },
        {
          text: this.modeHeader,
          align: 'start',
          value: 'name',
          filterable: keyword !== '' && keyword !== undefined && keyword !== null,
          filter: (e) => e.toUpperCase().includes(keyword.toUpperCase()),
        },
        {
          show: this.showDetail,
          text: 'Sum (year)',
          value: 'sumYear',
          filterable: this.showEmpty,
          filter: (e) => e > 0,
        },
        { show: this.showDetail, text: 'Month', value: 'month' },
        { show: this.showDetail, text: 'Transitive', value: 'transitive', sortable: false },
        { show: this.showDetail, text: 'Share', value: 'share' },
        { show: this.showDetail, text: 'YoY', value: 'yoy' },
        { show: Boolean(this.colors), text: '', width: '70px', value: 'color', sortable: false },
        { text: '', width: '70px', value: 'rawEdit', sortable: false },
        {
          show: this.colors && this.showChangeColor,
          text: '',
          width: '50px',
          value: '',
          sortable: false,
        },
        { show: this.rowEditable, text: '', value: 'edit' },
      ].filter((d) => d.show !== false)
      return array
    },
    // eslint-disable-next-line no-unused-vars
    calBar(): (val: number) => string {
      return (val) => {
        const percentageNum = 100 - val
        const percentageText = percentageNum + '%'
        const colorText = `rgb(${this.color})`
        return `linear-gradient(-90deg,transparent ${percentageText}, ${colorText} ${percentageText})`
      }
    },

    rowDatas(): RowData[] {
      const data = this.listData.map((obj) => ({
        ...obj,
        customOrder: this.itemSortOrder?.[obj.name],
      }))
      return data
    },

    listData(): RowData[] {
      const keys = Array.from(d3.group(this.tableGraphData, (d) => d[this.group]).keys())
      const data = keys.map((name) => this.getRowData(name))
      return data
    },

    totalOfLatestYear(): number {
      return d3
        .rollup(
          this.tableGraphData,
          (d) => d3.sum(d, (d) => d.total),
          (d) => d.year,
        )
        .get(this.latestYear)
    },

    maxSumYear(): number {
      const sumPerYear = d3.rollup(
        this.tableGraphData,
        (d) => d3.sum(d, (v) => v.total),
        (d) => d.year,
        (d) => d[this.group],
      )
      const latestSums = sumPerYear.get(this.latestYear)
      return d3.max(latestSums, (d) => d[1])
    },

    last4YearData(): Data[] {
      return d3.filter(this.tableGraphData, (d) => d.year >= this.latestYear - 3)
    },

    mappedData(): Map<string, Data[]> {
      return d3.group(this.last4YearData, (d) => d[this.group])
    },

    formattedRollupedData(): Map<string, Map<string, number>> {
      const data = d3.rollup(
        this.last4YearData.map(Data.format).flat(),
        (d) => d3.sum(d, (v) => v.value),
        (d) => d[this.group],
        (d) => d.date.format(dateFormat),
      )
      return data
    },

    modeHeader(): string {
      const viewModes = {
        country: 'Country',
        maker: 'Maker',
        w_category: 'Category',
        model: 'Model',
      }
      return viewModes?.[this.group] ?? ''
    },
  },
  methods: {
    clickHeaderCheck(check:boolean){
      this.$emit('update:selected',check?this.listData.map(d=>d.name):[])
    },
    filterName() {
      debounce(() => (this.keyword = this.tempKeyword), 300, false)()
    },

    getRowData(name: string): RowData {
      const id = name.replaceAll(/[/\s()/.]/g, '_')
      if (!this.showDetail) {
        return { id, name }
      }

      const { totalOfLatestYear } = this
      const { latestDate } = this
      const [thisYear, thisMonth] = latestDate.split('-').map(Number)

      const data = this.mappedData.get(name) ?? []
      const sums = d3.rollup(
        data.filter((d) => d.year === thisYear),
        (d) => d3.sum(d, (v) => v.total),
        (d) => d.year,
      )

      const sumYear = sums.get(thisYear) ?? 0

      // const formatted = this.formattedMappedData.get(name)

      const rollupByDate = this.formattedRollupedData.get(name)

      const thisDateKey = latestDate
      const month = rollupByDate?.get(thisDateKey) ?? 0
      const lastYearsDateKey = thisYear - 1 + '-' + thisMonth
      const monthOfLastYear = rollupByDate?.get(lastYearsDateKey) ?? 0
      const yoy = monthOfLastYear ? (month - monthOfLastYear) / monthOfLastYear : 0
      const share = sumYear / totalOfLatestYear

      return { id, name, sumYear, month, monthOfLastYear, yoy, share, rollupByDate }
    },

    getTransitive(rollupByDate: Map<string, number>): Transitive {
      const last3YearDates = (() => {
        const to = moment(this.latestDate)
        const array = []
        for (let i = 0; i < 36; i += 1) {
          array.push(to.clone().subtract(i, 'months'))
        }
        return array.reverse()
      })()
      const last3YearData = last3YearDates.map(
        (date) => rollupByDate?.get(date.format(dateFormat)) ?? 0,
      )
      const bar = last3YearData.filter((_, i) => i > 11).map((v) => v ?? 0)
      const redLine = (() => {
        const sums = []
        for (let i = 12; i < 36; i += 1) {
          let sum = 0
          for (let j = 0; j < i; j += 1) {
            sum += last3YearData[j] ?? 0
          }
          sums.push(sum)
        }
        return sums
      })()

      const greenLine = (() => {
        const sums = []
        for (let i = 0; i < 24; i += 1) {
          let sum = 0
          for (let j = 0; j < 12; j += 1) {
            sum += last3YearData[i + j] ?? 0
          }
          sums.push(sum)
        }
        return sums
      })()
      return { bar, greenLine, redLine }
    },

    currentItems(value: RowData[]) {
      if (!this.showDetail) return
      this.$nextTick(() => {
        $(`.sparkline.${this.graphIdPrefix}`).remove()

        value.forEach((data) => {
          const { bar, redLine, greenLine } = this.getTransitive(data.rollupByDate)
          const { id } = data
          const graphId = this.graphIdPrefix + id
          $(`#${graphId}`).append(
            `<span id="g${graphId}" class="sparkline ${this.graphIdPrefix}" ></span>`,
          )
          $(`#g${graphId}`).sparkline(bar || [], {
            myPrefixes: ['月'],
            tooltipFormatter: (_, options, fields) => {
              let format = $.spformat(
                '<div class="jqsfield"><span style="color: {{color}}">&#9679;</span> {{myprefix}} {{value}}</div>',
              )
              let result = ''
              $.each(fields, (i, field) => {
                const month = ((this.latestMonth + field.offset) % 12) + 1
                field.myprefix = month + options.get('myPrefixes')[i]
                result += format.render(field, options.get('tooltipValueLookups'), options)
              })
              return result
            },
            type: 'bar',
            barColor: '#4e6da0',
            width: 200,
            height: 30,
            title: '',
          })
          $(`#g${graphId}`).sparkline(redLine, {
            tooltipPrefix: '累計 ',
            lineColor: '#bb3434',
            composite: true,
            fillColor: false,
            disableHiddenCheck: true,
            width: 200,
            height: 30,
          })
          $(`#g${graphId}`).sparkline(greenLine, {
            tooltipPrefix: '移動累計 ',
            lineColor: '#4d9f3a',
            composite: true,
            fillColor: false,
            disableHiddenCheck: true,
            width: 200,
            height: 30,
          })
        })
      })
    },

    // colors関連
    calRGBA(val, positiveHex, negativeHex = '240, 80, 80') {
      const hex = val > 0 ? positiveHex : negativeHex
      return `rgba(${hex ?? this.color}, ${Math.abs(val)}`
    },

    toggleContextColor(event, item) {
      setTimeout(() => {
        event.preventDefault()
        let mousePosition = (this as any).handleMouseMove(event)
        this.colorChanging = item
        this.$nextTick(() => {
          $('.v-menu__content.menuable__content__active').css({
            top: mousePosition.y - 200 + 'px',
            left: mousePosition.x - 330 + 'px',
            width: '300px',
          })
        })
      })
    },

    changeColor(name, value) {
      this.$emit('change-color', { name, value })
    },
  },
  watch: {
    // parent change sortOrder => re-order item
    tableGraphData() {
      this.options.page = 1
    },
    itemSortOrder() {
      this.options.sortBy = ['customOrder']
    },
  },
  filters: {
    intFormat(value: number) {
      return new Intl.NumberFormat().format(value)
    },
    percentageFormat(value: number) {
      return Math.round(value * 10000) / 100
    },
  },
})
