

















import Vue, { PropOptions } from "vue";
import * as d3 from "d3";
import { Data } from "@/assets/types/Data";
import {
  EChartsOption,
  ECharts,
  LineSeriesOption,
  YAXisComponentOption,
  XAXisComponentOption,
} from "echarts";
import Tooltip from "./Tooltip.vue";
import { isEmpty } from "@/js/utils";
import { dataToArray, downloadCsv } from "@/assets/downloadCsv";
import { StoreQueryType } from "@/assets/types/StoreQueryType";
import Resizable from "@/components/Resizable.vue";
import { downloadGraph } from "@/js/downloadGraph";

export default Vue.extend({
  components: { Resizable },
  data: () => ({ myChart: null as ECharts, mounted: false }),
  props: {
    lineData: { type: Array } as PropOptions<Data.Formatted[]>,
    areaData: { type: Array } as PropOptions<Data.Formatted[]>,
    lineQuery: { type: Object } as PropOptions<StoreQueryType>,
    areaQuery: { type: Object } as PropOptions<StoreQueryType>,
  },
  async mounted() {
    await new Promise((resolve) => setTimeout(() => resolve(null), 500));
    this.mounted = true;
  },

  methods: {
    downloadGraph() {
      downloadGraph(this.$refs.chart);
    },
    downloadLineCsv() {
      downloadCsv(this.lineValues, "MultiLineChartLines");
      downloadCsv(this.lineRawValues, "MultiLineChartLines2");
    },
    downloadAreaCsv() {
      downloadCsv(this.areaValues, "MultiLineChartAreas");
      downloadCsv(this.areaRawValues, "MultiLineChartAreas2");
    },
    formatDate(data: Data.Formatted): string {
      const month = data.month + 1;
      return data.year + "-" + month;
    },
  },

  computed: {
    year(): [number, number] {
      return this.lineQuery.year;
    },
    groupByMakerData(): [string, [string, number][]][] {
      return d3.rollups(
        this.lineData,
        (v) => d3.sum(v, (d) => d.value),
        (d) => d.maker,
        (d) => this.formatDate(d),
      );
    },

    lineDataSums(): Map<string, number> {
      return d3.rollup(
        this.lineData,
        (v) => d3.sum(v, ({ value }) => value),
        (d) => this.formatDate(d),
      );
    },

    areaDataSums(): Map<number, number> {
      return d3.rollup(
        this.areaData,
        (v) => d3.sum(v, ({ value }) => value),
        (d) => +d.date,
      );
    },

    areaSeries(): LineSeriesOption {
      return {
        type: "line",
        yAxisIndex: 1,
        name: "台数",
        data: Array.from(this.areaDataSums),
        encode: { x: 0, y: 1 },
        areaStyle: {},
        animation: this.mounted,
      };
    },

    lineSeries(): LineSeriesOption[] {
      return this.groupByMakerData.map<LineSeriesOption>(([name, value]) => ({
        type: "line",
        data: value.map(([k, v]) => [
          k,
          this.lineDataSums.get(k) ? v / this.lineDataSums.get(k) : 0,
        ]),
        encode: { x: 0, y: 1 },
        itemStyle: { color: this.$store.getters["colors/makers"][name] },
        name,
        animation: this.mounted,
      }));
    },

    series(): LineSeriesOption[] {
      return [this.areaSeries, ...this.lineSeries];
    },

    grid() {
      return {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      };
    },
    axisFontSize(): number {
      return this.$store.getters["viewSettings/axisFontSize"];
    },

    percentAxis(): YAXisComponentOption {
      const formatter = (v) => String(Math.round(Number(v) * 1000) / 10);
      const fontSize = this.axisFontSize;
      return {
        type: "value",
        name: "%",
        nameTextStyle: { fontSize },
        axisLabel: { formatter, fontSize },
        axisPointer: {
          label: { formatter: ({ value }) => formatter(value) + "%", fontSize },
        },
        axisLine: { lineStyle: { color: "red" } },
      };
    },

    totalAxis(): YAXisComponentOption {
      const fontSize = this.axisFontSize;
      const formatter = this.$store.getters["viewSettings/formatter"];
      return {
        type: "value",
        name: `台数(${this.$store.getters["viewSettings/selectedUnit"].value})`,
        nameTextStyle: { fontSize },
        axisLabel: { formatter, fontSize },
        axisPointer: {
          label: { formatter: ({ value }) => formatter(Math.round(Number(value))), fontSize },
        },
      };
    },

    yAxis(): YAXisComponentOption[] {
      return [this.percentAxis, this.totalAxis];
    },

    years(): string[] {
      if (isEmpty(this.year)) return [];
      const array = Array.from(this.year);
      const min = array[0];
      const max = array[1];
      const years = [];
      for (let year = min; year <= max; year += 1) {
        for (let month = 1; month <= 12; month += 1) {
          years.push(year + "-" + month);
        }
      }
      return years;
    },

    xAxis(): XAXisComponentOption {
      const formatter = (v) => v.split("-")[0];
      const fontSize = this.axisFontSize;
      return {
        type: "category",
        data: this.years,
        axisLabel: { interval: 11, formatter, rotate: 30, fontSize },
        name: "date",
        nameTextStyle: { fontSize },
      };
    },

    tooltip(): EChartsOption["tooltip"] {
      return {
        trigger: "axis",
        axisPointer: { type: "cross" },
        formatter: ((data, t, cb) => {
          const tooltip = new Tooltip();
          tooltip.$props.data = data.reverse();
          tooltip.$mount(document.createElement("div"), true);
          this.$nextTick(() => {
            cb(t, tooltip.$el.innerHTML);
          });
          return "loading";
        }) as any,
      };
    },

    option(): EChartsOption {
      const { grid, xAxis, yAxis, series, tooltip } = this;
      return {
        grid,
        xAxis,
        yAxis,
        series,
        tooltip,
      };
    },

    lineValues(): (string | number)[][] {
      const columns = ["model", "date", "%"];
      const fuga = this.lineSeries
        .map((s) => {
          const model = s.name as string;
          const datas = (s.data as [string, number][]).map((d) => [model, ...d]);
          return datas;
        })
        .flat();
      return [columns, ...fuga];
    },

    lineRawValues(): (string | number)[][] {
      return dataToArray(this.$store.getters["data"](this.lineQuery));
    },

    areaValues(): (string | number)[][] {
      const columns = ["date", "台数"];
      const fuga = [this.areaSeries].map((s) => s.data as [string, number][]).flat();
      return [columns, ...fuga];
    },

    areaRawValues(): (string | number)[][] {
      return dataToArray(this.$store.getters["data"](this.areaQuery));
    },
  },
});
