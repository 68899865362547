










































































































































import Vue from "vue";
import { mapGetters } from "vuex";

import BarCharts from "./BarChart/BarChart.vue";
import StackBarCharts from "./StackBarChart/StackBarCharts.vue";
import MultiLineChart from "./MultiLineChart/MultiLineChart.vue";
import BubbleChart from "./BubbleChart/BubbleChart.vue";
import PercentageChart from "./PercentageChart/PercentageChart.vue";
import RankingChart from "./RankingChart/RankingChart.vue";
import TempGraph from "./Temp/tempGraph.vue";
import CumulativeMovementChart from "./CumulativeMovementChart/CumulativeMovementChart.vue";
import TheVariable from "./TheVariable.vue";
import TheViewSetting from "./TheViewSetting.vue";
import MarimekkoChart from "@/components/MarimekkoChart/MarimekkoChart.vue";
import MultiLineChartQuery from "./MultiLineChart/MultiLineChartQuery.vue";
import ActionableTableGraph from "@/components/TableGraph/ActionableTableGraph.vue";
import BaseNavi from "@/components/Base/BaseNavi.vue";
import Tab from "@/components/Variables/Tab.vue";
import IconLink from "@/components/Base/BaseIconLink.vue";

import { variableTitle } from "@/js/const";
import { Data } from "@/assets/types/Data";
import { deepClone } from "@/js/utils";
import { StoreQueryType } from "@/assets/types/StoreQueryType";

export default Vue.extend({
  name: "TheGraphs",
  components: {
    ActionableTableGraph,
    BarCharts,
    StackBarCharts,
    MultiLineChart,
    RankingChart,
    BubbleChart,
    MarimekkoChart,
    TheVariable,
    MultiLineChartQuery,
    CumulativeMovementChart,
    PercentageChart,
    TempGraph,
    Tab,
    IconLink,
    BaseNavi,
    TheViewSetting,
  },
  watch: {
    isFuture() {
      if (this.isFuture) {
        this.tab = 0
      }
    },
  },
  data: () => ({
    tab: 0,
    variable_tab: 0,
    data_tab: null,
    showMenu: false,
    showMultiLineChartQuery: false,
    query2: {} as StoreQueryType,
    showVariable: false,
    showViewSetting: false,
    viewMenu: false,
  }),
  async mounted() {
    this.query2 = deepClone(this.query);
  },
  computed: {
    ...mapGetters({ formattedData: 'formattedData', graphData: 'data', years: 'years' }),
    isFuture: {
      get(): boolean {
        return this.$store.getters.isFuture
      },
      async set(value: boolean) {
        await this.$store.dispatch('registerQuery', {
          query: { countries: this.query.countries, future: value },
        })
      },
    },
    charts(): any[] {
      const { isFuture } = this
      const disabled = isFuture
      return [
        { text: 'bar chart', icon: 'mdi-poll' },
        { text: 'stack bar chart', icon: 'mdi-chart-bar-stacked', disabled },
        { text: 'multi line chart', icon: 'mdi-chart-multiline', disabled },
        { text: '面積 chart', icon: 'mdi-chart-tree', disabled },
        { text: 'bubble chart', icon: 'mdi-chart-scatter-plot', disabled },
        { text: '移動累計', icon: 'mdi-chart-timeline-variant', disabled },
        { text: '百分率', icon: 'mdi-chart-arc', disabled },
        { text: 'temp', icon: 'mdi-chart-areaspline', disabled },
        { text: 'RANKING', icon: 'mdi-star', disabled },
      ]
    },
    variables(): readonly any[] {
      return variableTitle
    },
    query: {
      get(): StoreQueryType {
        return this.$store.getters.query;
      },

      set(val: StoreQueryType) {
        this.$store.dispatch("registerQuery", { query: val });
      },
    },

    filteredData(): Data[] {
      return this.graphData(this.query);
    },

    filteredFormattedData(): Data.Formatted[] {
      return this.formattedData(this.query);
    },

    filteredFormattedData2(): Data.Formatted[] {
      return this.formattedData(this.query2);
    },

    selectedCountries(): string[] {
      return this.$store.getters.selectedCountries;
    },
  },
  methods: {
    setVariable(tab: number) {
      this.variable_tab = tab;
      this.showVariable = true;
    },
  },
});
