<template>
  <v-text-field
    :class="classes"
    outlined
    filled
    ref="input"
    :type="type"
    :disabled="disabled"
    v-on="listeners"
    :label="label"
    :placeholder="placeholder"
    :hint="hint"
    :error="hasError"
    :success="valid"
    :value="$vModelDebounce"
    @focus="selectText"
    @input.native="updateValue($event.target.value)"
    @blur="formatValue"
    @keydown="preventInputCharachter"
  />
</template>

<script>
import {
  omit,
  round,
  formatNumber,
  isNotNullOrNumber,
  debounce,
} from "@/js/utils";
import vModelDebounce from "@/mixins/v-model-debounce";
import constants from "@/js/const";

export default {
  name: "BaseNumberFormatInput",
  mixins: [vModelDebounce],
  computed: {
    listeners() {
      return Object.assign(
        {},
        omit(this.$listeners, "click:append-outer", "click:prepend", "input")
      );
    },
    hasError() {
      return this.errors && this.errors.length > 0;
    },
  },
  props: {
    label: {
      type: String,
    },
    hint: {
      type: String,
    },

    placeholder: {
      type: String,
      default: "0.0",
    },

    classes: {
      type: String,
    },

    type: {
      type: String,
      default: "text",
    },

    valid: {
      type: Boolean,
    },

    errors: {
      type: [Array, String],
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    precision: {
      type: Number,
      default: 1,
    },
    round: {
      type: Boolean,
      default: true,
    },
    format: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.formatValue();
  },
  methods: {
    updateValue(val) {
      if (isNotNullOrNumber(val) && this.round) {
        val = round(val, this.precision);
      }
      this.updateVModelDebounce(val); //  updateVModelDebounce is from "@/mixins/v-model-debounce"
    },

    selectText(e) {
      // fix for Safari bug
      setTimeout(function () {
        e.target.select();
      }, 0);
    },

    formatValue: debounce(function () {
      if (this.format) {
        this.$refs.input.$el.querySelector("input").value = isNotNullOrNumber(
          this.cachedLocalValue
        )
          ? formatNumber({
              number: this.cachedLocalValue,
              precision: this.precision,
              thousand: "",
            })
          : null;
      }
    }, constants.debounceDelay.min),

    preventInputCharachter(e) {
      const charValue = String.fromCharCode(e.keyCode);
      // 231 is the keyCode for all number for Vietnamese keyboard
      // 190 is "."
      // 37,38,39,40 = arrow keys
      const allowKeyCodes = [190, 231, 37, 38, 39, 40];
      if (
        !allowKeyCodes.includes(e.which) &&
        isNaN(charValue) &&
        e.which != 8
      ) {
        e.preventDefault();
      }
    },
  },
};
</script>