












































import Vue, { PropOptions } from 'vue'
import * as d3 from 'd3'
import { Data } from '@/assets/types/Data'
import {
  EChartsOption,
  YAXisComponentOption,
  XAXisComponentOption,
  LegendComponentOption,
  BarSeriesOption,
} from 'echarts'
import Tooltip from './Tooltip.vue'
import { isEmpty } from '@/js/utils'
import { StoreQueryType } from '@/assets/types/StoreQueryType'
import { dataToArray, downloadCsv } from '@/assets/downloadCsv'
import Resizable from '@/components/Resizable.vue'
import { downloadGraph } from '@/js/downloadGraph'

export default Vue.extend({
  components: { Resizable },

  // 年ごと、月ごと
  // 月ごとは累積と通常の二種類の値
  // 累積/パーセンテージの二種の表示方法
  props: {
    lineData: { type: Array } as PropOptions<Data[]>,
    year: { type: Array } as PropOptions<number[]>,
    maker: { type: Array } as PropOptions<string[]>,
    w_category: { type: Array } as PropOptions<string[]>,
    query: { type: Object } as PropOptions<StoreQueryType>,
  },
  data: () => ({
    selectedMakers: {},
    selectedWCategories: {},
    selectedModels: {},
    mounted: false,
  }),
  async mounted() {
    await new Promise((resolve) => setTimeout(() => resolve(null), 500))
    this.mounted = true
  },

  methods: {
    downloadMakerGraph() {
      downloadGraph(this.$refs.makerChart)
    },
    downloadWCategoryGraph() {
      downloadGraph(this.$refs.wCategoryChart)
    },
    downloadModelGraph() {
      downloadGraph(this.$refs.wCategoryChart)
    },
    downloadMakerCsv() {
      downloadCsv(this.makerValues)
      downloadCsv(this.makerRawValues)
    },
    downloadWCategoryCsv() {
      downloadCsv(this.wCategoryValues)
      downloadCsv(this.wCategoryRawValues)
    },
    downloadModelCsv() {
      downloadCsv(this.modelValues)
      downloadCsv(this.modelRawValues)
    },

    isSelected(data: Data) {
      const model = this.reducedModels.includes(data.model) ? data.model : 'OTHERS'
      return (
        this.selectedMakers[data.maker] !== false &&
        this.selectedWCategories[data.w_category] !== false &&
        this.selectedModels[model] !== false
      )
    },
    updateSelectedMaker() {
      const { legend } = (this.$refs.makerChart as any).getOption()
      this.selectedMakers = legend[0].selected
      this.updateselectedWCategory()
    },
    updateselectedWCategory() {
      const { legend } = (this.$refs.wCategoryChart as any).getOption()
      this.selectedWCategories = legend[0].selected
      this.updateSelectedModel()
    },
    updateSelectedModel() {
      const { legend } = (this.$refs.modelChart as any).getOption()
      this.selectedModels = legend[0].selected
    },
  },

  computed: {
    axisFontSize(): number {
      return this.$store.getters['viewSettings/axisFontSize']
    },
    labelFontSize(): number {
      return this.$store.getters['viewSettings/labelFontSize']
    },
    makerValues(): (string | number)[][] {
      const columns = ['maker', 'date', 'sum']
      const fuga = this.makerSeries
        .map((s) => {
          const maker = s.name as string
          const datas = (s.data as [string, number][]).map((d) => [maker, ...d])
          return datas
        })
        .flat()

      return [columns, ...fuga.filter(([maker]) => this.makerLegend.selected[maker] !== false)]
    },
    makerRawValues(): (string | number)[][] {
      const datas = this.$store.getters['data'](this.query) as Data[]
      const filtered = datas.filter((data) => this.makerLegend.selected[data.maker] !== false)
      return dataToArray(filtered)
    },
    wCategoryValues(): (string | number)[][] {
      const columns = ['w_category', 'date', 'sum']
      const fuga = this.wCategorySeries
        .map((s) => {
          const wCategory = s.name as string
          const datas = (s.data as [string, number][]).map((d) => [wCategory, ...d])
          return datas
        })
        .flat()

      return [
        columns,
        ...fuga.filter(([wCategory]) => this.wCategoryLegend.selected[wCategory] !== false),
      ]
    },
    wCategoryRawValues(): (string | number)[][] {
      const datas = this.$store.getters['data'](this.query) as Data[]
      const filtered = datas.filter((data) => this.makerLegend.selected[data.maker] !== false)
      return dataToArray(
        filtered.filter((d) => this.wCategoryLegend.selected[d.w_category] !== false),
      )
    },
    modelValues(): (string | number)[][] {
      const columns = ['model', 'date', 'sum']
      const fuga = this.modelSeries
        .map((s) => {
          const model = s.name as string
          const datas = (s.data as [string, number][]).map((d) => [model, ...d])
          return datas
        })
        .flat()

      return [columns, ...fuga.filter(([model]) => this.modelLegend.selected[model] !== false)]
    },
    modelRawValues(): (string | number)[][] {
      const datas = this.$store.getters['data'](this.query) as Data[]
      const filtered = datas.filter((data) => this.makerLegend.selected[data.maker] !== false)
      return dataToArray(
        filtered
          .filter((d) => this.wCategoryLegend.selected[d.w_category] !== false)
          .filter((d) => this.modelLegend.selected[d.model] !== false),
      )
    },
    totalsOfYear(): Map<number, number> {
      return d3.rollup(
        this.lineData,
        (v) => d3.sum(v, (d) => (this.isSelected(d) ? d.total : 0)),
        (d) => d.year,
      )
    },

    groupByMakerData(): Map<string, Map<number, number>> {
      return d3.rollup(
        this.lineData,
        (v) => d3.sum(v, (d) => (this.isSelected(d) ? d.total : 0)),
        (d) => d.maker,
        (d) => d.year,
      )
    },

    makerSeries(): BarSeriesOption[] {
      const fontSize = this.labelFontSize
      return Array.from(this.groupByMakerData.entries()).map<BarSeriesOption>(([maker, values]) => {
        return {
          type: 'bar',
          data: Array.from(values).map(([year, v]) => [year, v / this.totalsOfYear.get(year)]),
          itemStyle: { color: this.$store.getters['colors/makers'][maker], fontSize },
          label: { fontSize },
          stack: 'total',
          encode: { x: 1, y: 0 },
          name: String(maker),
          animation: this.mounted,
        }
      })
    },

    wCategorySeries(): BarSeriesOption[] {
      const fontSize = this.labelFontSize
      const group = d3.rollup(
        this.lineData,
        (v) => d3.sum(v, (d) => (this.isSelected(d) ? d.total : 0)),
        (d) => d.w_category,
        (d) => d.year,
      )
      return Array.from(group.entries()).map<BarSeriesOption>(([name, values]) => {
        return {
          type: 'bar',
          data: Array.from(values).map(([year, v]) => [year, v / this.totalsOfYear.get(year)]),
          label: { fontSize },
          stack: 'total',
          encode: { x: 1, y: 0 },
          name,
          animation: this.mounted,
        }
      })
    },

    reducedModels(): string[] {
      const { lineData } = this
      const filtered = d3.filter(
        lineData,
        (d) =>
          this.selectedMakers[d.maker] !== false &&
          this.selectedWCategories[d.w_category] !== false,
      )
      return d3
        .rollups(
          filtered,
          (v) => d3.sum(v, (d) => d.total),
          (d) => d.model,
        )
        .sort((a, b) => b[1] - a[1])
        .slice(0, 8)
        .map((d) => d[0])
        .concat(['OTHERS'])
    },

    modelSeries(): BarSeriesOption[] {
      const fontSize = this.labelFontSize
      const group = d3.rollup(
        this.lineData,
        (v) => d3.sum(v, (d) => (this.isSelected(d) ? d.total : 0)),
        (d) => (this.reducedModels.includes(d.model) ? d.model : 'OTHERS'),
        (d) => d.year,
      )
      return Array.from(group.entries()).map<BarSeriesOption>(([name, values]) => {
        return {
          type: 'bar',
          data: Array.from(values).map(([year, v]) => [year, v / this.totalsOfYear.get(year)]),
          stack: 'total',
          encode: { x: 1, y: 0 },
          name,
          animation: this.mounted,
          label: { fontSize },
        }
      })
    },

    grid() {
      return {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      }
    },

    yAxis(): YAXisComponentOption {
      const fontSize = this.axisFontSize
      return {
        type: 'category',
        axisLabel: { fontSize },
      }
    },

    years(): string[] {
      if (isEmpty(this.year)) return []
      const [min, max] = Array.from(this.year)
      const years = []
      for (let year = min; year <= max; year += 1) {
        years.push(String(year))
      }
      return years
    },

    xAxis(): XAXisComponentOption {
      const fontSize = this.axisFontSize
      return {
        type: 'value',
        max: 1,
        axisLabel: {
          formatter: (v) => String(100 * v),
          fontSize,
        },
      }
    },

    tooltip(): EChartsOption['tooltip'] {
      return {
        trigger: 'axis',
        // axisPointer: { type: "cross" },
        formatter: ((data, t, cb) => {
          const tooltip = new Tooltip()
          tooltip.$props.data = data.reverse()
          tooltip.$mount(document.createElement('div'), true)
          this.$nextTick(() => cb(t, tooltip.$el.innerHTML))
          return 'loading'
        }) as any,
      }
    },

    makerLegend(): LegendComponentOption {
      return {
        data: this.maker,
        selected: this.selectedMakers,
      }
    },

    wCategoryLegend(): LegendComponentOption {
      return {
        data: this.w_category,
        selected: this.selectedWCategories,
      }
    },

    modelLegend(): LegendComponentOption {
      return {
        data: this.reducedModels,
        selected: this.selectedModels,
      }
    },

    wCategoryOption(): EChartsOption {
      const { grid, xAxis, yAxis, wCategorySeries: series, tooltip, wCategoryLegend: legend } = this

      return { grid, xAxis, yAxis, series, tooltip, legend }
    },

    makerOption(): EChartsOption {
      const { grid, xAxis, yAxis, makerSeries: series, tooltip, makerLegend: legend } = this
      return { grid, xAxis, yAxis, series, tooltip, legend }
    },

    modelOption(): EChartsOption {
      const { grid, xAxis, yAxis, modelSeries: series, tooltip, modelLegend: legend } = this
      return { grid, xAxis, yAxis, series, tooltip, legend }
    },
  },
})
