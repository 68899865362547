

































import Vue from "vue";
import Variables from "@/components/Variables/index.vue";
import { StoreQueryType } from "@/assets/types/StoreQueryType";
import { deepClone, deepEqual, isEmpty } from "@/js/utils";
import BaseConfirmDialog from "./Base/BaseConfirmDialog.vue";
import { RESET_TEMP_COLORS } from "@/store/modules/colors";
import { mapMutations } from "vuex";
type ColorEmit = { name: string; value: string };

export default Vue.extend({
  components: { Variables, BaseConfirmDialog },
  props: {
    show: { type: Boolean, default: false },
    tab: { type: [Number, String], required: true },
  },

  data() {
    return {
      loadingCountryData: false,
      localQueryOption: null as StoreQueryType,
      showCountryChangeDialog: false,
    };
  },
  mounted() {
    this.fetchTempColors();
    this.localQueryOption = deepClone(this.query);
  },
  watch: {
    show() {
      if (this.show) {
        this.fetchTempColors();
      }
    },
    query: {
      handler(val) {
        this.localQueryOption = deepClone(val);
      },
    },
  },
  methods: {
    ...mapMutations({
      updateGraphModel: "updateModelData",
    }),
    fetchTempColors() {
      this.$store.dispatch("colors/fetchTempColors");
    },
    saveColors() {
      this.$store.dispatch("colors/save");
    },

    async onTabChange(tabToSwitch: number) {
      const { tab } = this;
      this.$emit("update:tab", tabToSwitch);
      if (this.countriesChanged) {
        await this.$nextTick();
        const isConfirmed = await (this.$refs.countryChangeDialog as any).open();
        if (isConfirmed) {
          await this.search();
        } else {
          this.$emit("update:tab", tab);
          this.localQueryOption.countries = this.query.countries;
        }
      }
    },
    async search() {
      const query = { ...this.localQueryOption };
      this.saveColors();
      // to-do: error handling for API
      if (this.countriesChanged) {
        this.registerQuery({ query: { countries: query.countries, future: query.future } });
      } else {
        this.registerQuery({ query });
      }
    },

    registerQuery(query: any) {
      this.$store.dispatch("registerQuery", query);
    },

    updateColor(key: string, { name, value }: ColorEmit) {
      this.$store.dispatch("colors/setTempColor", { key, color: { [name]: value } });
    },

    resetColors(key: string) {
      this.$store.dispatch("colors/" + RESET_TEMP_COLORS, { key });
    },

    // 以下applyConditionにだけ使われているメソッド
    async applyCondition({ query, modelEditHistory }: any) {
      query = { ...query };
      if (!this.countriesChanged) {
        this.$nextTick(() => this.$emit("update:countriesChanged", false));
      }
      if (!isEmpty(modelEditHistory)) {
        for (const [modelName, { origin, new: newModel }] of Object.entries(
          modelEditHistory as any[],
        )) {
          // TODO updateGraphModelが存在しない？
          const updateModel = {
            ...newModel,
            name: modelName,
            wCategory: newModel.w_category,
          };
          this.updateGraphModel(updateModel);
          this.updateModelEditHistory({
            modelName,
            origin,
            newModel,
          });
        }
      }
      if (query.colors.maker) {
        query.colors.maker.forEach(({ key, value }) => {
          this.updateColor("maker", { name: key, value });
        });
      }
      if (query.colors.w_category) {
        query.colors.w_category.forEach(({ key, value }) => {
          this.updateColor("wCategory", { name: key, value });
        });
      }
      if (query.colors.model) {
        query.colors.model.forEach(({ key, value }) => {
          this.updateColor("model", { name: key, value });
        });
      }
      this.saveColors();
      this.registerQuery({ query });
    },

    updateModelEditHistory(val: any) {
      this.$store.commit("tableGraph/updateModelEditHistory", val);
    },
  },
  computed: {
    modelApplyMakerColor: {
      get(): boolean {
        return this.$store.getters["colors/modelApplyMakerColor"];
      },
      set(value: boolean) {
        this.$store.dispatch("colors/setModelApplyMakerColor", value);
      },
    },

    countriesChanged(): boolean {
      return !deepEqual(this.query.countries, this.localQueryOption.countries);
    },

    query(): StoreQueryType {
      return this.$store.getters.query;
    },
    options(): StoreQueryType {
      const {
        wCategories,
        manufacturers,
        countries,
        models,
        engines,
        dates,
        prices,
      } = this.$store.getters;
      return {
        w_category: wCategories,
        maker: manufacturers,
        countries,
        model: models,
        cc: engines,
        date: dates,
        price: prices,
      };
    },
  },
});
