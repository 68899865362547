












import SortButton from "@/components/Variables/Sort/SortButton.vue";
import Vue from "vue";

export default Vue.extend({
  components: { SortButton },
});
