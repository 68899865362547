




// @ is an alias to /src
import Vue from "vue";
import myv from "@/components/TheGraphs.vue";

export default Vue.extend({
  name: "Graphs",
  components: { myv },
  data: () => ({ mounted: false }),

  async mounted() {
    const cachedQuery = this.$localStorage.get("graphQuery") ?? { countries: ["USA"] };
    const viewSettings = this.$localStorage.get("viewSettings");
    await this.$store.dispatch("updateShowMonth", this.$localStorage.get("showMonth"));
    await this.$store.dispatch("colors/init", this.$localStorage.get("colors"));
    await this.$store.dispatch("tableGraph/init");
    await this.$store.dispatch("registerQuery", { query: cachedQuery, willCache: false });
    if (viewSettings) {
      const { selectedUnitOfValueId, showTableGraph, labelFontSize, axisFontSize } = viewSettings;
      this.$store.dispatch("viewSettings/selectUnit", selectedUnitOfValueId);
      this.$store.dispatch("viewSettings/updateShowTableGraph", showTableGraph);
      this.$store.dispatch("viewSettings/updateLabelFontSize", labelFontSize);
      this.$store.dispatch("viewSettings/updateAxisFontSize", axisFontSize);
    }
    this.$store.dispatch("links/init", this.$store.getters.query.countries);
    this.mounted = true;
  },
});
