<template>
  <validation-provider :mode="mode" ref="provider" :name="label" :rules="rules" v-slot="{ errors, valid }">
    <span class="error--text">{{ Array.isArray(errors) ? errors.join('\n') : errors }}</span>
    <slot v-bind:errors="errors" v-bind:valid="valid">
    </slot>
  </validation-provider>
</template>
<script>
export default {
  name: "ValidationCheckbox",
  props: {
    label: {
      type: String,
      required: true,
    },
    rules: {
      default: "required_array",
    },
    mode: {
        type: [Function, String]
    }
  }
};
</script>