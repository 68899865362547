<template>
  <v-btn
    icon
    :color="btnColor"
    @click="$emit('click')"
    :class="btnClasses"
    :disabled="btnDisabled"
    ><v-icon :size="btnSize">{{ convertName }}</v-icon></v-btn
  >
</template>
<script>
const dict = {
  bar: "mdi-chart-bar",
  line: "mdi-chart-line",
  stack: "mdi-layers-triple-outline",
  download: "mdi-download",
};
export default {
  props: {
    btnDisabled: {
      type: Boolean,
      default: false,
    },
    btnClasses: {
      type: Object,
      default: () => ({}),
    },
    btnName: {
      type: String,
      default: "bar",
    },
    btnSize: {
      type: String,
      default: "default",
    },
    btnOn: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    convertName() {
      return dict[this.btnName];
    },
    btnColor() {
      return this.btnOn ? "light-blue" : "grey";
    },
  },
};
</script>

