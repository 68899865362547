



























































































































































































































































import Vue from 'vue'
import { dateFormat, isEmpty } from '@/js/utils'
import ValidationText from '@/components/Validation/ValidationText.vue'
import firebase from 'firebase/app'
import { createNamespacedHelpers } from 'vuex'

require('firebase/database')

const searchConditionRef = firebase.database().ref('/search-conditions')

const { mapGetters: graphGetters } = createNamespacedHelpers('tableGraph')

export default Vue.extend({
  name: 'ConfirmSearchConditions',
  components: { ValidationText },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: [Object, Array],
      default: () => {},
    },
  },
  data: () => ({
    dataFormatted: {} as { [key: string]: any },
    searchConditionRef,
    items: {
      maker: 'メーカー',
      w_category: 'カテゴリ',
      model: 'モデル',
      cc: '排気量',
      kw: 'EV',
      date: '期間',
      price: '価格',
      countries: '地域',
    },
    dialogName: false,
    viewListData: false,
    detailCondition: false,
    checkMyOwn: true,
    checkApplySearch: false,
    searchCondition: '',
    listSearchConditions: [],
    conditionName: dateFormat(new Date(), 'YYYY-MM-DD HH:mm'),
    localModelEditHistory: [], // $ variables are local
  }),
  computed: {
    ...graphGetters(['modelEditHistory']),
    authUser(): firebase.User {
      return firebase.auth().currentUser
    },
    listDataFiltered(): any[] {
      return [...this.listSearchConditions].filter((e) => e.title.includes(this.searchCondition))
    },
    hasEditedModel(): boolean {
      return !isEmpty(this.localModelEditHistory)
    },
  },
  watch: {
    data(val) {
      this.mapData(val)
    },
    show() {
      this.viewListData = false
      this.detailCondition = false
    },
    checkMyOwn(val) {
      this.viewConditions(true, val)
    },
    // only update local modelEditHistory if not showing detail condition from other users
    modelEditHistory: {
      handler(val) {
        if (!this.detailCondition) {
          this.localModelEditHistory = val
        }
      },
      immediate: true,
    },
    detailCondition(val) {
      if (!val) {
        this.mapData(this.data)
        this.localModelEditHistory = this.modelEditHistory
      }
    },
  },
  methods: {
    hideModal() {
      this.$emit('close')
    },
    formatValue(value) {
      // if (value && value.length) {
      //   let res = []
      //   value.map((e) => {
      //     res.push(e.text ? e.text : e)
      //   })
      //   return res.join(' / ')
      // }
      // return ''
      let res = []
      if (value && value.length) {
        value.map((e) => {
          res.push(e.text ? e.text : e)
        })
      }
      return res
    },
    mapData(data) {
      this.dataFormatted.colors = { ...data.colors }
      Object.keys({ ...this.items }).map((e) => {
        this.dataFormatted[e] = {
          key: this.items[e],
          value: data[e] ? this.formatValue(data[e]) : [],
        }
        this.dataFormatted = { ...this.dataFormatted }
      })
    },
    saveCondition() {
      this.conditionName = dateFormat(new Date(), 'YYYY-MM-DD HH:mm')
      this.dialogName = true
    },
    findColor(data, key) {
      if (data && data.length) {
        let item = data.find((e) => e.key === key)
        if (item) {
          return item.value
        } else {
          return 'primary'
        }
      }
      return 'primary'
    },
    confirmSave() {
      let key = (this.searchConditionRef.push() as any).getKey()
      this.searchConditionRef.child(key).set({
        id: key,
        title: this.conditionName,
        data: this.data,
        modelEditHistory: this.modelEditHistory,
        created_id: this.authUser.uid,
        created_name: this.authUser.displayName,
        created_email: this.authUser.email,
        createdAt: dateFormat(new Date(), 'YYYY-MM-DD HH:mm'),
      })
      this.dialogName = false
      this.conditionName = ''
      this.viewConditions(true)
    },
    viewConditions(search = false, owner = true) {
      if (search || !this.listSearchConditions || !this.listSearchConditions.length) {
        let listData
        listData = owner
          ? this.searchConditionRef.orderByChild('created_id').equalTo(this.authUser.uid)
          : this.searchConditionRef.orderByChild('created_id')
        listData
          .once('value', (snapshot) => {
            this.listSearchConditions = []
            snapshot.forEach((doc) => {
              let data = doc.val()
              data.checked = false
              this.listSearchConditions.push(data)
            })
          })
          .then(() => {
            if (search) this.listSearchConditions = [...this.listSearchConditions]
            this.viewListData = true
          })
      } else {
        this.viewListData = true
      }
    },
    detailSearchCondition(condition) {
      this.mapData(condition.data)
      this.localModelEditHistory = condition.modelEditHistory // get modelEditHistory from fb
      this.detailCondition = true
    },
    closeDetailCondition() {
      this.detailCondition = false
    },
    clearChecked(index) {
      let flag = false
      this.listSearchConditions.map((e, i) => {
        if (i !== index) e.checked = false
        if (e.checked) flag = true
      })
      this.checkApplySearch = flag
    },
    applyCondition() {
      const checkedSerchCondition = this.listSearchConditions.find(
        (searchCondition) => searchCondition.checked,
      )
      if (checkedSerchCondition) {
        const data = { ...checkedSerchCondition.data }
        const { modelEditHistory } = checkedSerchCondition
        if (data.cc) {
          data.cc.map((e) => {
            if (!e.from) e.from = null
            if (!e.to) e.to = null
          })
        }
        if (data.price) {
          data.price.map((e) => {
            if (!e.from) e.from = 0
            if (!e.to) e.to = null
          })
        }
        // if (data.colors) {
        //   const toDict = (data) =>
        //     data
        //       .map(({ key, value }) => ({ [key]: value }))
        //       .reduce((pre, cur) => ({ ...pre, ...cur }), {})
        //   data.colors.maker && (data.colors.maker = toDict(data.colors.maker))
        //   data.colors['w_category'] && (data.colors['w_category'] = toDict(data.colors['w_category']))
        //   data.colors.model && (data.colors.model = toDict(data.colors.model))
        // }
        this.$emit('apply-condition', { query: data, modelEditHistory })
        this.hideModal()
        return
      }
    },
    removeSearchCondition(id, index) {
      this.searchConditionRef.child(id).remove()
      this.listSearchConditions.splice(index, 1)
    },
  },
})
