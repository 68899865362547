<template>
  <v-dialog
    v-model="isShown"
    :max-width="maxWidth"
    @click:outside="closeClicked"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-card-title>
        <v-row class="ma-0">
          <v-col cols="12" lg="9" md="12" sm="12" class="align-self-center">
            <p class="ma-0 caption">
              {{ this.message }}
            </p>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" dark @click="confirmClicked">{{
          confirmBtnText
        }}</v-btn>
        <v-btn color="primary" outlined @click="closeClicked">{{
          closeBtnText
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "BaseConfirmDialog",
  data() {
    return {
      awaitResponse: null,
    };
  },
  props: {
    message: {
      type: String,
      required: true,
    },
    isShown: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: [String, Number],
      default: 400,
    },
    confirmBtnText: {
      type: String,
      default: "OK",
    },
    closeBtnText: {
      type: String,
      default: "戻る",
    },
  },
  methods: {
    confirmClicked() {
      this.awaitResponse
        ? this.awaitResponse(true) && (this.awaitResponse = null)
        : this.$emit("confirm");
      this.$emit("update:isShown", false);
    },
    closeClicked() {
      this.awaitResponse
        ? this.awaitResponse(false) && (this.awaitResponse = null)
        : this.$emit("close");
      this.$emit("update:isShown", false);
    },
    // await Promise approach instead of event
    open() {
      this.$emit("update:isShown", true);
      return new Promise((resolve) => {
        this.awaitResponse = resolve;
      });
    },
  },
};
</script>

