<template>
  <v-menu
    ref="menu"
    v-model="showDate"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    :min-width="menuMinWidth"
  >
    <template v-slot:activator="{ on }">
      <validation-provider
        :name="label"
        :rules="rules"
        v-slot="{ errors, valid }"
      >
        <v-text-field
          :value="formattedDate"
          :label="label"
          prepend-icon="mdi-calendar"
          readonly
          :error-messages="errors"
          :success="valid"
          outlined
          filled
          v-on="on"
        ></v-text-field>
      </validation-provider>
    </template>
    <v-date-picker
      ref="picker"
      :value="value"
      :min="minDate"
      :max="maxDate"
      :type="customType"
      locale="ja"
      @input="saveDate"
      reactive
    ></v-date-picker>
  </v-menu>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return { showDate: false };
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    menuMinWidth: {
      type: String,
      default: "290px",
    },
    minDate: {
      type: String,
      default: () => {
        var now = moment();
        return now.format("YYYY-MM-DD");
      },
    },
    maxDate: {
      type: String,
      default: () => {
        var now = moment();
        return now.add(5, "years").format("YYYY-MM-DD");
      },
    },
    type: {
      type: String,
      value: "date",
    },
    rules: {
      default: "required",
    },
    value: {
      required: true,
    },
  },
  computed: {
    customType() {
      // custom type = year because vuetify have not supported type 'year' yet
      return this.type === "year" ? "month" : this.type;
    },
    formattedDate() {
      // vuetify date picker does not support format YYYY yet, so convert to YYYY-MM
      return this.type === "year"
        ? moment(this.value.toString()).format("YYYY")
        : this.value.toString();
    },
  },
  watch: {
    showDate(val) {
      if (val) {
        switch (this.type) {
          case "year":
            this.$nextTick(() => (this.$refs.picker.activePicker = "YEAR"));
            break;

          case "month":
            this.$nextTick(() => (this.$refs.picker.activePicker = "MONTH"));
            break;

          default:
            this.$nextTick(() => (this.$refs.picker.activePicker = "DATE"));
            break;
        }
      }
    },
  },
  methods: {
    saveDate(date) {
      this.$refs.menu.save(date);
      this.$emit("input", date);
      // Close the menu/datepicker
      this.showDate = false;
    },
  },
};
</script>
