












































import Vue, { PropOptions } from "vue";
import * as d3 from "d3";
import { Data } from "@/assets/types/Data";
import {
  EChartsOption,
  LineSeriesOption,
  YAXisComponentOption,
  XAXisComponentOption,
  LegendComponentOption,
} from "echarts";
import Tooltip from "./Tooltip.vue";
import { isEmpty } from "@/js/utils";
import { dataToArray, downloadCsv } from "@/assets/downloadCsv";
import { StoreQueryType } from "@/assets/types/StoreQueryType";
import Resizable from "@/components/Resizable.vue";
import { downloadGraph } from "@/js/downloadGraph";

export default Vue.extend({
  components: { Resizable },
  props: {
    lineData: { type: Array } as PropOptions<Data.Formatted[]>,
    year: { type: Array } as PropOptions<number[]>,
    maker: { type: Array } as PropOptions<string[]>,
    query: { type: Object } as PropOptions<StoreQueryType>,
  },
  data: () => ({
    selectedMakers: {},
    selectedWCategories: {},
    selectedModels: {},
    mounted: false,
  }),
  async mounted() {
    await new Promise((resolve) => setTimeout(() => resolve(null), 500));
    this.mounted = true;
  },
  methods: {
    downloadMakerCsv() {
      downloadCsv(this.makerValues);
      downloadCsv(this.makerRawValues);
    },
    downloadWCategoryCsv() {
      downloadCsv(this.wCategoryValues);
      downloadCsv(this.wCategoryRawValues);
    },
    downloadMakerGraph() {
      downloadGraph(this.$refs.makerChart);
    },

    downloadWCategoryGraph() {
      downloadGraph(this.$refs.wCategoryChart);
    },

    downloadModelGraph() {
      downloadGraph(this.$refs.wCategoryChart);
    },

    downloadModelCsv() {
      downloadCsv(this.modelValues);
      downloadCsv(this.modelRawValues);
    },

    dateFormat(d: number): string {
      const date = new Date(d);
      return date.getFullYear() + "-" + (date.getMonth() + 1);
    },

    updateSelectedMaker() {
      const { legend } = (this.$refs.makerChart as any).getOption();
      this.selectedMakers = legend[0].selected;
      this.updateselectedWCategory();
    },

    updateselectedWCategory() {
      const { legend } = (this.$refs.wCategoryChart as any).getOption();
      this.selectedWCategories = legend[0].selected;
      this.updateSelectedModel();
    },

    updateSelectedModel() {
      const { legend } = (this.$refs.wCategoryChart as any).getOption();
      this.selectedModels = legend[0].selected;
    },
  },

  computed: {
    axisFontSize(): number {
      return this.$store.getters["viewSettings/axisFontSize"];
    },
    labelFontSize(): number {
      return this.$store.getters["viewSettings/labelFontSize"];
    },
    makerValues(): (string | number)[][] {
      const columns = ["maker", "date", "sum"];
      const flatted = this.makerSeries
        .map(({ data, name }) => (data as [string, number][]).map((d) => [name, ...d]))
        .flat();

      const { selected } = this.makerLegend;

      return [columns, ...flatted.filter(([maker]) => selected[maker] !== false)];
    },
    makerRawValues(): (string | number)[][] {
      const d = this.$store.getters["data"](this.query) as Data[];
      const { selected } = this.makerLegend;
      const filtered = d.filter(({ maker }) => selected[maker] !== false);
      return dataToArray(filtered);
    },

    wCategoryValues(): (string | number)[][] {
      const columns = ["w_category", "date", "sum"];
      const flatted = this.wCategorySeries
        .map(({ data, name }) => (data as [string, number][]).map((d) => [name, ...d]))
        .flat();
      const { selected } = this.wCategoryLegend;

      return [columns, ...flatted.filter(([wCategory]) => selected[wCategory] !== false)];
    },
    wCategoryRawValues(): (string | number)[][] {
      const datas = this.$store.getters["data"](this.query) as Data[];
      const filtered = datas.filter((data) => this.makerLegend.selected[data.maker] !== false);
      return dataToArray(
        filtered.filter((d) => this.wCategoryLegend.selected[d.w_category] !== false),
      );
    },
    modelValues(): (string | number)[][] {
      const columns = ["model", "date", "sum"];
      const fuga = this.modelSeries
        .map((s) => {
          const model = s.name as string;
          const datas = (s.data as [string, number][]).map((d) => [model, ...d]);
          return datas;
        })
        .flat();

      return [columns, ...fuga.filter(([model]) => this.modelLegend.selected[model] !== false)];
    },
    modelRawValues(): (string | number)[][] {
      const datas = this.$store.getters["data"](this.query) as Data[];
      const filtered = datas.filter((data) => this.makerLegend.selected[data.maker] !== false);
      return dataToArray(
        filtered
          .filter((d) => this.wCategoryLegend.selected[d.w_category] !== false)
          .filter((d) => this.modelLegend.selected[d.model] !== false),
      );
    },

    groupByMakerData(): Map<string, Map<number, number>> {
      return d3.rollup(
        this.lineData,
        (v) => d3.sum(v, (d) => d.value),
        (d) => d.maker,
        (d) => +d.date,
      );
    },

    makerSeries(): LineSeriesOption[] {
      const fontSize = this.labelFontSize;
      return Array.from(this.groupByMakerData.entries()).map<LineSeriesOption>(([name, values]) => {
        return {
          type: "line",
          data: Array.from(values.entries()).map(([d]) => {
            let sum = 0;
            for (let i = 0; i < 12; i += 1) {
              const date = new Date(d);
              const pre = date.setMonth(date.getMonth() - i);
              const value = values.get(+pre) ?? 0;
              sum += value;
            }
            return [this.dateFormat(d), sum];
          }),
          itemStyle: { color: this.$store.getters["colors/makers"][name], fontSize },
          encode: { x: 0, y: 1 },
          animation: this.mounted,
          name,
        };
      });
    },

    wCategorySeries(): LineSeriesOption[] {
      const fontSize = this.labelFontSize;
      const filtered = d3.filter(this.lineData, (d) => this.selectedMakers[d.maker] !== false);
      const group = d3.rollup(
        filtered,
        (v) => d3.sum(v, (d) => d.value),
        (d) => d.w_category,
        (d) => +d.date,
      );
      return Array.from(group.entries()).map<LineSeriesOption>(([name, values]) => {
        return {
          type: "line",
          animation: this.mounted,
          data: Array.from(values.entries()).map(([d]) => {
            let sum = 0;
            for (let i = 0; i < 12; i += 1) {
              const date = new Date(d);
              const pre = date.setMonth(date.getMonth() - i);
              const value = values.get(+pre) ?? 0;
              sum += value;
            }
            return [this.dateFormat(d), sum];
          }),
          itemStyle: {
            color: this.$store.getters["colors/wCategories"][name],
            fontSize,
          },
          name,
        };
      });
    },

    modelSeries(): LineSeriesOption[] {
      const fontSize = this.labelFontSize;
      const filtered = d3.filter(
        this.lineData,
        (d) =>
          this.selectedMakers[d.maker] !== false && this.selectedWCategories[d.w_category] !== false,
      );
      const group = d3.rollup(
        filtered,
        (v) => d3.sum(v, (d) => d.value),
        (d) => (this.reducedModels.includes(d.model) ? d.model : "OTHERS"),
        (d) => +d.date,
      );
      return Array.from(group.entries()).map<LineSeriesOption>(([name, values]) => {
        return {
          type: "line",
          data: Array.from(values.entries()).map(([d]) => {
            let sum = 0;
            for (let i = 0; i < 12; i += 1) {
              const date = new Date(d);
              const pre = date.setMonth(date.getMonth() - i);
              const value = values.get(+pre) ?? 0;
              sum += value;
            }
            return [this.dateFormat(d), sum];
          }),
          itemStyle: { color: this.$store.getters["colors/models"][name], fontSize },
          animation: this.mounted,
          name,
        };
      });
    },

    reducedModels(): string[] {
      const { lineData } = this;
      const filtered = d3.filter(
        lineData,
        (d) =>
          this.selectedMakers[d.maker] !== false && this.selectedWCategories[d.w_category] !== false,
      );
      return d3
        .rollups(
          filtered,
          (v) => d3.sum(v, (d) => d.value),
          (d) => d.model,
        )
        .sort((a, b) => b[1] - a[1])
        .slice(0, 8)
        .map((d) => d[0])
        .concat(["OTHERS"]);
    },

    grid() {
      return {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      };
    },

    yAxis(): YAXisComponentOption {
      const formatter = this.$store.getters["viewSettings/formatter"];
      const fontSize = this.axisFontSize;
      return {
        type: "value",
        name: `台数(${this.$store.getters["viewSettings/selectedUnit"].value})`,
        nameTextStyle: { fontSize },
        axisLabel: { formatter, fontSize },
        axisPointer: {
          label: {
            formatter: ({ value }) => formatter(Math.round(Number(value))),
            fontSize,
          },
        },
        axisLine: { lineStyle: { color: "red" } },
      };
    },

    years(): string[] {
      if (isEmpty(this.year)) return [];
      const [min, max] = Array.from(this.year);
      const years = [];
      for (let year = min; year <= max; year += 1) {
        for (let month = 1; month <= 12; month += 1) {
          years.push(year + "-" + month);
        }
      }
      return years;
    },

    xAxis(): XAXisComponentOption {
      const fontSize = this.axisFontSize;
      const formatter = (v) => v.split("-")[0];
      return {
        type: "category",
        data: this.years,
        axisLabel: { interval: 11, formatter, rotate: 30, fontSize },
      };
    },

    tooltip(): EChartsOption["tooltip"] {
      return {
        trigger: "axis",
        axisPointer: { type: "cross" },
        formatter: ((data, t, cb) => {
          const tooltip = new Tooltip();
          tooltip.$props.data = data.reverse();
          tooltip.$mount(document.createElement("div"), true);
          this.$nextTick(() => cb(t, tooltip.$el.innerHTML));
          return "loading";
        }) as any,
      };
    },

    makerLegend(): LegendComponentOption {
      return {
        data: this.maker,
        selected: this.selectedMakers,
      };
    },

    wCategoryLegend(): LegendComponentOption {
      return {
        data: Array.from(d3.group(this.lineData, (d) => d.w_category).keys()),
        selected: this.selectedWCategories,
      };
    },

    modelLegend(): LegendComponentOption {
      return {
        data: this.reducedModels,
        selected: this.selectedModels,
      };
    },
    wCategoryOption(): EChartsOption {
      const { grid, xAxis, yAxis, wCategorySeries: series, tooltip, wCategoryLegend: legend } = this;
      return { grid, xAxis, yAxis, series, tooltip, legend };
    },

    makerOption(): EChartsOption {
      const { grid, xAxis, yAxis, makerSeries: series, tooltip, makerLegend: legend } = this;
      return { grid, xAxis, yAxis, series, tooltip, legend };
    },

    modelOption(): EChartsOption {
      const { grid, xAxis, yAxis, modelSeries: series, tooltip, modelLegend: legend } = this;
      return { grid, xAxis, yAxis, series, tooltip, legend };
    },
  },
});
