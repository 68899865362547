


















import Vue, { PropOptions } from 'vue'
import * as d3 from 'd3'
import { Data } from '@/assets/types/Data'
import StackBarChart from '@/components/StackBarChart/StackBarChart.vue'

export default Vue.extend({
  components: { StackBarChart },
  props: {
    cc: Array,
    year: Array as PropOptions<number[]>,
    maker: Array as PropOptions<string[]>,
    data: Array as PropOptions<Data[]>,
  },

  data() {
    return {
      maxValues: {},
      isAlign: false,
    }
  },
  watch: {
    maker() {
      this.updateMaxValue()
    },
  },

  methods: {
    updateMaxValue(maker?: string, max?: number) {
      this.maxValues[maker] = max
      this.maxValues = { ...this.maxValues }
    },
  },

  computed: {
    maxValue(): number {
      const keys = Object.keys(this.maxValues)
      const values = keys.map((key) => this.maxValues[key])
      return d3.max(values)
    },

    group(): Map<string, Data[]> {
      return d3.group(this.data, (d) => d.maker)
    },

    dataOf() {
      return (maker: string) => this.group.get(maker) ?? []
    },
  },
})
