










import Vue from 'vue'

export default Vue.extend({
  data() {
    return {
      dragPoint: null,
      frameHeight: '700px',
    }
  },
  mounted() {
    window.addEventListener('mousemove', this.onDrag)
    window.addEventListener('mouseup', this.onDragEnd)
  },
  methods: {
    onDragStart(e: MouseEvent) {
      const { x, y } = e
      this.dragPoint = { x, y }
    },
    onDrag({ x, y }: MouseEvent) {
      if (this.isDragging) {
        const frame = this.$refs.frame as HTMLDivElement
        const graph = this.$refs.graph as HTMLDivElement
        const { width, height } = graph.getBoundingClientRect()
        const newHeight = height - (this.dragPoint.y - y)
        frame.style.width = width - (this.dragPoint.x - x) + 'px'
        frame.style.height = newHeight + 'px'
      }
    },
    onDragEnd() {
      if (this.isDragging) {
        const frame = this.$refs.frame as HTMLDivElement
        const graph = this.$refs.graph as HTMLDivElement
        graph.style.width = frame.style.width
        graph.style.height = frame.style.height
        this.frameHeight = graph.style.height
      }
      this.dragPoint = null
    },
  },
  beforeDestroy() {
    window.removeEventListener('mousemove', this.onDrag)
    window.removeEventListener('mouseup', this.onDragEnd)
  },
  computed: {
    isDragging(): boolean {
      return this.dragPoint !== null
    },
  },
})
