<template>
  <span>
    <icon-btn :btn-on="isBar" @click="$emit('update:isBar', !isBar)"></icon-btn>
    <br />
    <icon-btn
      :btn-on="!isBar"
      btn-name="line"
      @click="$emit('update:isBar', !isBar)"
    ></icon-btn
    ><br />
    <icon-btn
      :btn-on="isStack"
      btn-name="stack"
      @click="$emit('update:isStack', !isStack)"
    ></icon-btn
    ><br />
    <icon-btn btn-name="download" @click="$emit('image-download')"></icon-btn
    ><br />
    <v-btn @click="$emit('csv-download')">DL Data</v-btn>
  </span>
</template>
<script>
import IconBtn from "@/components/Base/BaseIconButton";
export default {
  name: "BtnIcon",
  components: {
    IconBtn,
  },
  props: {
    isBar: {
      type: Boolean,
    },
    isStack: {
      type: Boolean,
    },
  },
};
</script>
