import constants from "@/js/const";
import emitDebounce from "@/mixins/emit-debounce";
import Vue from 'vue';
import { isString } from "@/js/utils";

export default Vue.util.mergeOptions(emitDebounce, {
    mixins: [emitDebounce],
    data() {
        return {
            cachedLocalValue: this.value
        }
    },
    props: {
        value: {
            require: true,
        },
        vmodelDebounce: {
            type: [String, Number],
            require: true,
            // check enum against constant.debounceDelay if String
            validator: (val) => isString(val) ?
                Object.prototype.hasOwnProperty.call(constants.debounceDelay, val) :
                true // allow number to pass
        }
    },
    computed: {
        vModelDebounceDelay() {
            return isString(this.vmodelDebounce) ?
                constants.debounceDelay[this.vmodelDebounce] :
                this.vmodelDebounce
        },
        $vModelDebounce: {
            get() {
                return this.cachedLocalValue;
            },

            set(val) {
                this.updateVModelDebounce(val);
            }
        }
    },

    methods: {
        updateVModelDebounce(val) {
            const updateVal = this.transformVModelValue(val);
            this.cachedLocalValue = updateVal;
            this.vModelDebounceDelay ?
                this.$emitDebounce('input', this.cachedLocalValue, this.vModelDebounceDelay) :
                this.$emit('input', this.cachedLocalValue)
        },
        // components that use this mixin can override this method
        // this method must return the val
        transformVModelValue(val) {
            return val;
        }
    },

    watch: {
        value(newVal) {
            this.cachedLocalValue = newVal;
        }
    }
});