










































































import Vue, { PropOptions } from "vue";
import * as d3 from "d3";
import { Data } from "@/assets/types/Data";
import { dataToArray, downloadCsv } from "@/assets/downloadCsv";
import { StoreQueryType } from "@/assets/types/StoreQueryType";
import { createNamespacedHelpers } from "vuex";
import Resizable from "@/components/Resizable.vue";
import ChartSort from "@/components/BarChart/Sort.vue";
const { mapGetters: colorGetters } = createNamespacedHelpers("colors");
import { downloadGraphFromSvg } from "@/js/downloadGraph";

const margin = { top: 30, right: -1, bottom: -1, left: 1 };
const format = (d: number) => d.toLocaleString();

export default Vue.extend({
  components: { Resizable, ChartSort },
  props: {
    data: { type: Array } as PropOptions<Data.Formatted[]>,
    cc: Array,
    year: Array as PropOptions<number[]>,
    date: Array as PropOptions<string[]>,
    query: Object as PropOptions<StoreQueryType>,
  },
  data: () => ({
    sort: null,
    selectedSortIndex: 0,
    wCategoryThreshold: 2,
    makerThreshold: 10,
    modelThreshold: 10,
    customPriorities: {} as { [key: string]: any },
    clicked: null as {
      propertyName: "maker" | "w_category";
      value: string;
    } | null,
    wCategorySort: [] as string[],
  }),
  watch: {
    data() {
      this.drawSvg();
      this.drawSort();
    },
    customPriorities() {
      this.drawSvg();
      this.drawSort();
    },
    sort() {
      this.drawSvg();
    },
    wCategorySort() {
      this.drawSvg();
    },
    axisFontSize: "adjustFontSize",
    labelFontSize: "adjustFontSize",
  },

  computed: {
    ...colorGetters({
      colors: "wCategories",
    }),
    axisFontSize(): number {
      return this.$store.getters["viewSettings/axisFontSize"];
    },
    labelFontSize(): number {
      return this.$store.getters["viewSettings/labelFontSize"];
    },
    // makerValues(): (string | number)[][] {
    makerValues(): any {
      const columns = ["w_category", "maker", "value"];
      return [
        columns,
        ...Array.from(this.dataGroup)
          .map((d: any) => Array.from(d[1]))
          .flat()
          .map((d) => Array.from(d[1]))
          .flat()
          .map((d: Data.Formatted) => [d.w_category, d.maker, d.value]),
      ];
    },
    makerRawValues(): (string | number)[][] {
      return dataToArray(this.$store.getters["data"](this.query));
    },
    modelValues(): (string | number)[][] {
      const columns = [this.clicked.propertyName, "model", "value"];
      return [
        columns,
        ...Array.from(this.dataGroup2)
          .map((d: any) => Array.from(d[1]))
          .flat()
          .map((d) => Array.from(d[1]))
          .flat()
          .map((d: Data.Formatted) => [
            d[this.clicked.propertyName],
            d.model,
            d.value,
          ]),
      ];
    },
    modelRawValues(): (string | number)[][] {
      let filteredQuery = (() => {
        const { clicked } = this;
        const temp = { ...this.query };
        if (clicked.propertyName === "w_category") {
          temp.w_category = [clicked.value];
        } else {
          temp.maker = [clicked.value];
        }
        return temp;
      })();
      return dataToArray(this.$store.getters["data"](filteredQuery));
    },
    filteredData(): Data.Formatted[] {
      const { date, year } = this;
      const dateToNumber = (date: string) => {
        const [year, month] = date.split("-");
        return Number(year) * 12 + Number(month);
      };
      if (date) {
        const min = dateToNumber(date[0]);
        const max = dateToNumber(date[1]);
        return d3.filter(this.data, (d) => {
          const date = d.year * 12 + d.month + 1;
          return date >= min && date <= max;
        });
      } else if (year) {
        return d3.filter(
          this.data,
          (d) => d.year >= year[0] && d.year <= year[1]
        );
      }
      return this.data;
    },
    dataGroup(): any {
      const { sum, filteredData, wCategoryThreshold, makerThreshold } = this;
      const all = d3.sum(filteredData, sum);
      const inSum = (v) => d3.sum(v, sum);
      const wCategorySums = d3.rollup(filteredData, inSum, (d) => d.w_category);
      const percent1 = wCategoryThreshold / 100;
      const percent2 = makerThreshold / 100;
      const reducedWCategory = d3.group(filteredData, (d) =>
        wCategorySums.get(d.w_category) / all > percent1
          ? d.w_category
          : "OTHERS"
      );

      const sums = new Map();
      reducedWCategory.forEach((value, key) => {
        const wCategorySum = d3.sum(value, sum);
        const eachMakersSum = d3.rollup(value, inSum, (d) => d.maker);
        const group = d3.group(value, (d) =>
          eachMakersSum.get(d.maker) / wCategorySum > percent2
            ? d.maker
            : "OTHERS"
        );
        sums.set(key, group);
      });
      if (this.wCategorySort.length > 0) {
        return new Map(
          [...sums.entries()].sort((a, b) => {
            return (
              this.wCategorySort.findIndex((d) => d === a[0]) -
              this.wCategorySort.findIndex((d) => d === b[0])
            );
          })
        );
      }
      return sums;
    },
    dataGroup2(): any {
      if (!this.clicked) {
        return null;
      }
      const {
        clicked,
        sum,
        filteredData,
        wCategoryThreshold,
        makerThreshold,
        modelThreshold,
      } = this;
      // const otherPropertyName =
      //   clicked.propertyName === "maker" ? "w_category" : "maker";
      const inSum = (v) => d3.sum(v, sum);
      if (clicked.propertyName === "w_category") {
        const clickedWCategory = d3
          .group(filteredData, (d) => d.w_category)
          .get(clicked.value);
        const wCategorySum = d3
          .rollup(filteredData, inSum, (d) => d.w_category)
          .get(clicked.value);
        const makerSums = d3.rollup(clickedWCategory, inSum, (d) => d.maker);
        // const percent1 = categoryThreshold / 100;
        const percent2 = makerThreshold / 100;
        const percent3 = modelThreshold / 100;
        const reducedMaker = d3.group(clickedWCategory, (d) =>
          makerSums.get(d.maker) / wCategorySum > percent2 ? d.maker : "OTHERS"
        );
        const sums = new Map();
        reducedMaker.forEach((value, key) => {
          const makerSum = d3.sum(value, sum);
          const eachNamesSum = d3.rollup(value, inSum, (d) => d.model);
          const group = d3.group(value, (d) =>
            eachNamesSum.get(d.model) / makerSum > percent3 ? d.model : "OTHERS"
          );
          sums.set(key, group);
        });
        return sums;
      } else {
        const clickedMaker = d3
          .group(filteredData, (d) => d.maker)
          .get(clicked.value);
        const makerSum = d3
          .rollup(filteredData, inSum, (d) => d.maker)
          .get(clicked.value);
        const wCategorySums = d3.rollup(
          clickedMaker,
          inSum,
          (d) => d.w_category
        );
        const percent1 = wCategoryThreshold / 100;
        // const percent2 = makerThreshold / 100;
        const percent3 = modelThreshold / 100;
        const reducedMaker = d3.group(clickedMaker, (d) =>
          wCategorySums.get(d.w_category) / makerSum > percent1
            ? d.w_category
            : "OTHERS"
        );
        const sums = new Map();
        for (let [key, value] of reducedMaker) {
          const makerSum = d3.sum(value, sum);
          const eachNamesSum = d3.rollup(value, inSum, (d) => d.model);
          const group = d3.group(value, (d) =>
            eachNamesSum.get(d.model) / makerSum > percent3 ? d.model : "OTHERS"
          );
          sums.set(key, group);
        }
        return sums;
      }
    },

    color(): d3.ScaleOrdinal<string, string, never> {
      const domain = this.makers;
      return d3.scaleOrdinal(d3.schemeCategory10).domain(domain);
    },

    makers(): string[] {
      return Array.from(d3.group(this.filteredData, (d) => d.maker).keys());
    },

    sorts(): any {
      // OTHER最優先(最後)
      const otherIsLast: any = (a, b) => {
        if (a.data[0]?.toString().toLowerCase() === "others") {
          return 1;
        } else if (b.data[0]?.toString().toLowerCase() === "others") {
          return -1;
        } else return undefined;
      };
      const alphabet = (a, b) => {
        return (
          ((a.data[0] as string)?.toLowerCase() as any) -
          ((b.data[0] as string)?.toLowerCase() as any)
        );
      };
      const value = (a, b) => b.value - a.value;
      return [
        {
          title: "アルファベット順",
          logic: (a, b) => otherIsLast(a, b) ?? alphabet(a, b),
        },
        { title: "台数順", logic: (a, b) => otherIsLast(a, b) ?? value(a, b) },
        {
          title: "カスタム",
          logic: (a, b) => otherIsLast(a, b) ?? this.customSort(a, b),
        },
      ];
    },
    //コンポーネントに渡す並び替え用データ
    labelAndColor(): any {
      return Array.from(this.dataGroup).map((e: string) => ({
        label: e[0],
        color: this.colors[e[0]],
      }));
    },
    customSorted(): any {
      return this.makers.map((m) => m).sort(this.customSort);
    },
  },

  async mounted() {
    this.drawSvg();
    this.drawSort();
  },
  methods: {
    setSort(labels) {
      if (this.wCategorySort.length > 0) {
        this.wCategorySort.splice(0, this.wCategorySort.length);
      }
      this.wCategorySort.push(...labels);
    },
    adjustFontSize() {
      const container = this.$refs.container as HTMLDivElement;
      const container2 = this.$refs.container2 as HTMLDivElement;
      const hoge = (elem: HTMLElement) => {
        if (elem) {
          const width = elem.offsetWidth;
          const height = elem.offsetHeight;
          const obj = d3.select(elem);

          obj.selectAll(".axis text").attr("font-size", this.axisFontSize);
          obj.selectAll(".label text").attr("font-size", this.labelFontSize);
          obj
            .select("svg")
            .attr(
              "viewBox",
              [-this.axisFontSize * 8, 0, width, height].toString()
            );
        }
      };
      hoge(container);
      hoge(container2);
    },
    downloadMakerGraph() {
      const ele = (this.$refs.container as HTMLDivElement)
        .firstChild as SVGSVGElement;
      downloadGraphFromSvg(ele);
    },

    downloadModelGraph() {
      const ele = (this.$refs.container2 as HTMLDivElement)
        .firstChild as SVGSVGElement;
      downloadGraphFromSvg(ele);
    },

    downloadMakerCsv() {
      downloadCsv(this.makerValues);
      downloadCsv(this.makerRawValues);
    },
    downloadModelCsv() {
      downloadCsv(this.modelValues);
      downloadCsv(this.modelRawValues);
    },
    customSort(a, b) {
      const p = this.customPriorities;
      return p[a.data?.[0]] - p[b.data?.[0]];
    },
    drawSort() {
      if (this.sort?.title !== "カスタム") {
        return;
      }
      const contianer = this.$refs.customSort as HTMLDivElement;
      contianer.innerHTML = null;
      const { makers, customSorted } = this;
      const svg = d3
        .create("svg")
        .attr("viewBox", [0, 0, 500 * makers.length, 500].toString())
        .style("font", "20px sans-serif");
      contianer.appendChild(svg.node() as any);

      const node = svg.selectAll("g").data(customSorted).join("g");

      node
        .attr("transform", (d, i) => `translate(${i * 500}, 0)`)
        .attr("stroke", "black")
        .attr("fill", "none")
        .attr("fill-opacity", 0.4)
        .attr("cursor", "pointer")
        .on("mouseenter", (d) => d3.select(d.target).attr("fill-opacity", 1))
        .on("mouseleave", (d) => d3.select(d.target).attr("fill-opacity", 0.4))
        .call(this.drag());

      node
        .append("rect")
        .attr("x", 50)
        .attr("y", 50)
        .attr("width", 400)
        .attr("height", 400)
        .attr("stroke", "black")
        .attr("fill", (d: any) => this.color(d));

      node
        .append("text")
        .attr("x", "250")
        .attr("y", "48%")
        .attr("text-anchor", "middle")
        .text((d: any) => d);
    },

    drag() {
      // eslint-disable-next-line no-unused-vars
      function dragstarted(this: any) {
        d3.select(this).raise().attr("stroke", "black");
      }

      function dragged(this: SVGElement, event) {
        d3.select(this)
          .attr("transform", `translate(${event.x}, 0)`)
          .attr("cy", event.y);
      }

      const self = this;

      function dragended(this: any, event, d) {
        const x = event.x / 500 - 0.5;
        const temp = { ...self.customPriorities };
        temp[d] = x;
        self.makers
          .sort((a, b) => temp[a] - temp[b])
          .map((maker, i) => (temp[maker] = i));
        self.customPriorities = temp;
      }

      return d3
        .drag()
        .on("start", dragstarted)
        .on("drag", dragged)
        .on("end", dragended);
    },
    sum(d: Data.Formatted) {
      return d.value ?? 0;
    },
    treemap(group: any, width: number, height: number) {
      // selectedSort,
      // customPriorities,
      const cus = this.sort?.logic;
      return d3
        .treemap()
        .round(true)
        .tile(d3.treemapSliceDice)
        .size([
          width - margin.left - margin.right,
          height - margin.top - margin.bottom,
        ])(
          d3
            .hierarchy(group)
            .sum((d) => d.value ?? 0)
            .sort(cus)
        )
        .each((d) => {
          const { x0, x1, y0, y1 } = d;
          d.x0 = y0;
          d.x1 = y1;
          d.y0 = x0;
          d.y1 = x1;
        });
    },
    async drawSvg() {
      await Vue.nextTick();

      const container = this?.$refs?.container as HTMLDivElement;
      if (!container) {
        return;
      }
      // container.innerHTML = null
      const width = container.offsetWidth;
      const height = container.offsetHeight;
      const root = this.treemap(this.dataGroup, height, width);

      const svg = d3
        .create("svg")
        .attr("width", container.offsetWidth)
        .attr("height", container.offsetHeight)
        .attr(
          "viewBox",
          [-100, 0, container.offsetWidth, container.offsetHeight].toString()
        )
        .style("font", "10px sans-serif");

      const rootHeight = root.y1 - root.y0;

      const node = svg
        .selectAll("g")
        .data(root.descendants().filter((d) => d.depth < 3))
        .join("g")
        .attr("transform", (d) => `translate(${d.x0},${d.y0})`);

      const column = node
        .filter((d) => d.depth === 1)
        .filter((d) => (d.y1 - d.y0) / rootHeight > 1 / 100)
        .attr("class", "axis")
        .on("mousedown", (_, d) => {
          this.clicked = { propertyName: "w_category", value: d.data[0] };
          this.drawSvg2();
        });

      column
        .append("text")
        .attr("font-size", "1em")
        .attr("x", "-1em")
        .attr("y", "1em")
        .attr("fill", "black")
        .attr("text-anchor", "end")
        .style("font-weight", "bold")
        .text((d) => d.data[0]);

      column
        .append("text")
        .attr("x", "-1em")
        .attr("y", "2.2em")
        .attr("text-anchor", "end")
        .style("font-weight", "bold")
        .text((d) => d.value.toLocaleString());

      const cell = node.filter((d) => d.depth === 2);

      const group = cell
        .append("svg")
        .on("mousedown", (_, d) => {
          this.clicked = { propertyName: "maker", value: d.data[0] };
          this.drawSvg2();
        })
        .attr("width", (d) => Math.max(d.x1 - d.x0 - 1, 0))
        .attr("height", (d) => Math.max(d.y1 - d.y0 - 1, 0))
        .append("g");

      group
        .append("rect")
        .attr(
          "fill",
          (d) => this.$store.getters["colors/makers"][(d as any).data[0]]
        )
        .attr("fill-opacity", 0.5)
        .attr("width", (d) => Math.max(d.x1 - d.x0 - 1, 0))
        .attr("height", (d) => Math.max(d.y1 - d.y0 - 1, 0));

      const textable = group
        // .select('rect')
        .attr("class", "label")
        .filter((d) => d.x1 - d.x0 > width / 100);

      textable
        .append("text")
        .attr("x", 3)
        .attr("y", String(1.1) + "em")
        .text((d) => (d as any).data[0]);

      textable
        .append("text")
        .attr("x", 3)
        .attr("y", String(1.2 + 1.1) + "em")
        .attr("fill-opacity", 1)
        .text((d) => format(d.value as any));

      if (container.firstElementChild) {
        container.replaceChild(svg.node(), container.firstElementChild);
      } else {
        container.appendChild(svg.node());
      }
      this.drawSvg2();
      this.adjustFontSize();
    },

    drawSvg2() {
      const { treemap, dataGroup2 } = this;
      if (!dataGroup2) {
        return;
      }
      const container = this.$refs.container2 as HTMLDivElement;
      const width = container.offsetWidth;
      const height = container.offsetHeight;
      const root = treemap(dataGroup2, height, width);

      const svg = d3
        .create("svg")
        .attr("width", container.offsetWidth)
        .attr("height", container.offsetHeight)
        .attr("viewBox", [-100, 0, width, height].toString())
        .style("font", "10px sans-serif");
      const node = svg
        .selectAll("g")
        .data(root.descendants().filter((d) => d.depth < 3))
        .join("g")
        .attr("transform", (d) => `translate(${d.x0},${d.y0})`);

      const rootHeight = root.y1 - root.y0;
      const column = node
        .filter((d) => d.depth === 1)
        .filter((d) => (d.y1 - d.y0) / rootHeight > 1 / 100)
        .attr("class", "axis");

      column
        .append("text")
        .attr("x", "-1em")
        .attr("y", "1em")
        .attr("text-anchor", "end")
        .style("font-weight", "bold")
        .text((d) => d.data[0]);

      column
        .append("text")
        .attr("x", "-1em")
        .attr("y", "2.2em")
        .attr("text-anchor", "end")
        .style("font-weight", "bold")
        .text((d) => d.value.toLocaleString());

      const cell = node.filter((d) => d.depth === 2);
      const group = cell
        .append("svg")
        .attr("width", (d) => Math.max(d.x1 - d.x0 - 1, 0))
        .attr("height", (d) => Math.max(d.y1 - d.y0 - 1, 0))
        .append("g");

      group
        .append("rect")
        .attr(
          "fill",
          (d) => this.$store.getters["colors/models"][(d as any).data[0]]
        )
        .attr("fill-opacity", 0.5)
        .attr("width", (d) => Math.max(d.x1 - d.x0 - 1, 0))
        .attr("height", (d) => Math.max(d.y1 - d.y0 - 1, 0));

      const textable = group
        .filter((d) => d.x1 - d.x0 > width / 100)
        .attr("class", "label");

      textable
        .append("text")
        .attr("x", 3)
        .attr("y", String(1.1) + "em")
        .text((d) => d.data[0]);

      textable
        .append("text")
        .attr("x", 3)
        .attr("y", String(2.3) + "em")
        .attr("fill-opacity", 0.7)
        .text((d) => format(d.value));

      if (container.firstElementChild) {
        container.replaceChild(svg.node(), container.firstElementChild);
      } else {
        container.appendChild(svg.node());
      }
      this.adjustFontSize();
    },
  },
});
