

















import { SortType } from "@/js/sortDataKey";
import Vue, { PropOptions } from "vue";
export default Vue.extend({
  props: {
    value: { type: Object, required: true } as PropOptions<SortType>,
  },
  methods: {
    sort(algorithm: string) {
      const { value } = this;
      const asc = algorithm === value.algorithm ? !value.asc : true;
      this.$emit("input", { algorithm, asc });
    },
  },
});
