<template>
  <v-btn
    text
    v-show="isShow"
    @click="$emit('clear-click')"
    :class="btnClasses"
    :disabled="btnDisabled"
    class="red--text"
  >
    検索条件クリア
    <v-icon>mdi-close</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    btnDisabled: {
      type: Boolean,
      default: false,
    },
    isShow: {
      type: Boolean,
      default: false,
    },
    btnClasses: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

            