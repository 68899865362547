



























































import Vue, { PropOptions } from "vue";
import * as d3 from "d3";
import { Data } from "@/assets/types/Data";
import {
  EChartsOption,
  YAXisComponentOption,
  XAXisComponentOption,
  LegendComponentOption,
  ScatterSeriesOption,
  DatasetComponentOption,
  TimelineComponentOption,
} from "echarts";
import Resizable from "@/components/Resizable.vue";
import {
  DataZoomComponentOption,
  EChartsType,
} from "echarts/types/dist/shared";
import { dataToArray, downloadCsv } from "@/assets/downloadCsv";
import { downloadGraph } from "@/js/downloadGraph";

export default Vue.extend({
  components: { Resizable },
  props: {
    maker: { type: Array, required: true } as PropOptions<string[]>,
    data: { type: Array, required: true } as PropOptions<Data[]>,
    year: { type: Array, required: true } as PropOptions<number[]>,
  },
  data: () => ({
    selectedYear: 2021,
    symbolSizeFactor: 0.5,
    valueFilter: [0, 200000],
    xStart: 0,
    xEnd: 10000,
    yStart: 0,
    yEnd: 10000,
    isLabelDraggable: false,
  }),
  mounted() {
    const echarts = (this.$refs.chart as any).chart as EChartsType;
    echarts.on("dblclick", (param: any) => {
      const data = param.data as Data;
      const { cc, price } = data;
      const { dataZoom } = echarts.getOption();
      const x = (dataZoom as Array<any>).find((data) => data.id === "sliderX");
      const y = (dataZoom as Array<any>).find((data) => data.id === "sliderY");
      if (x) {
        const val = x.end - x.start;
        const val2 = (100 * cc) / this.maxCc;
        this.xStart = Math.max(0, val2 - val / 4);
        this.xEnd = val2 + val / 4;
        console.log(this.xStart);
        console.log(this.xEnd);
      }
      if (y) {
        const val = y.end - y.start;
        const val2 = (100 * price) / this.maxPrice;
        this.yStart = Math.max(val2 - val / 4);
        this.yEnd = val2 + val / 4;
      }
    });
  },
  methods: {
    updateIsLabelDraggable() {
      this.updateDataZoom();
      this.isLabelDraggable = !this.isLabelDraggable;
    },
    downloadGraph() {
      downloadGraph(this.$refs.chart);
    },
    getValues(): (string | number)[][] {
      const columns = ["model", "maker", "year", "cc", "price", "value"];
      const fuga = this.datasets
        .flat(2)
        .map((s) =>
          (s.source as Partial<Data>[]).map((d) => [
            d.model,
            d.maker,
            d.year,
            d.cc,
            d.price,
            d.total,
          ])
        )
        .flat();

      const option = (
        this.$refs.chart as any
      ).chart.getOption() as EChartsOption;
      const legend = option.legend[0];
      return [
        columns,
        ...fuga.filter(([, maker]) => legend.selected[maker] !== false),
      ];
    },

    getRawValues(): (string | number)[][] {
      const option = (
        this.$refs.chart as any
      ).chart.getOption() as EChartsOption;
      const legend = option.legend[0];
      return dataToArray(
        this.data.filter((v) => legend.selected[v.maker] !== false)
      );
    },
    downloadCsv() {
      downloadCsv(this.getValues(), "BubbleChart");
      downloadCsv(this.getRawValues(), "BubbleChart2");
    },
    updateSymbolSizeFactor(value: any) {
      this.updateDataZoom();
      this.symbolSizeFactor = value;
    },
    updateValueFilter(value: any) {
      this.updateDataZoom();
      this.valueFilter = value;
    },
    updateDataZoom() {
      const echarts = (this.$refs.chart as any).chart as EChartsType;
      if (!echarts) return;
      const { dataZoom } = echarts.getOption();
      const x = (dataZoom as Array<any>).find((data) => data.id === "sliderX");
      const y = (dataZoom as Array<any>).find((data) => data.id === "sliderY");
      if (x) {
        this.xStart = x?.start;
        this.xEnd = x?.end;
      }
      if (y) {
        this.yStart = y?.start;
        this.yEnd = y?.end;
      }
    },
  },
  computed: {
    axisFontSize(): number {
      return this.$store.getters["viewSettings/axisFontSize"];
    },
    labelFontSize(): number {
      return this.$store.getters["viewSettings/labelFontSize"];
    },
    continuousYears(): number[] {
      const [min, max] = Array.from(this.year);
      const years = [];
      for (let i = min; i <= max; i += 1) {
        years.push(i);
      }
      return years;
    },
    timeline(): TimelineComponentOption {
      return {
        show: false,
        axisType: "category",
        orient: "vertical",
        autoPlay: false,
        inverse: true,
        playInterval: 1000,
        left: null,
        right: 0,
        top: 20,
        bottom: 20,
        width: 55,
        height: null,
        symbol: "none",
        checkpointStyle: { borderWidth: 2 },
        controlStyle: { showNextBtn: false, showPrevBtn: false },
        data: this.continuousYears,
        currentIndex: this.currentIndex,
      };
    },
    currentIndex(): number {
      return Math.max(
        this.continuousYears.findIndex((y) => y === this.selectedYear),
        0
      );
    },
    grouped(): Map<number, Map<string, Partial<Data>[]>> {
      return d3.rollup(
        this.data,
        (v) =>
          d3
            .rollups(
              v,
              (v) => {
                const { cc, model, price, year, maker } = v[0];
                const total = d3.sum(v, (d) => d.total);
                return { cc, model, price, total, year, maker };
              },
              (d) => d.model
            )
            .map(([, v]) => v),
        (d) => d.year,
        (d) => d.maker
      );
    },
    legend(): LegendComponentOption {
      const { selectedYear } = this;
      const index = this.continuousYears.findIndex((d) => d == selectedYear);
      return {
        right: "10%",
        top: "3%",
        data: d3
          .groups(
            this.datasets[index].filter((d) => d.source.length > 0),
            (d) => (d.id as string)!
          )
          .map((d) => d[0]),
      };
    },
    grid: () => ({ left: "8%", top: "10%" }),
    maxPrice(): number {
      return (
        d3.max(
          this.data.filter((d) => d.year == this.selectedYear),
          (d) => d.price
        ) + 1
      );
    },
    maxCc(): number {
      return (
        d3.max(
          this.data.filter((d) => d.year == this.selectedYear),
          (d) => d.cc
        ) + 1
      );
    },
    xAxis(): XAXisComponentOption {
      const fontSize = this.axisFontSize;
      return {
        name: "cc",
        nameTextStyle: { fontSize },
        splitLine: { lineStyle: { type: "dashed" } },
        axisPointer: {
          label: { formatter: ({ value }) => Math.round(value) },
          fontSize,
        },
        axisLabel: { fontSize },
        scale: true,
        max: this.maxCc,
        min: 0,
      };
    },
    yAxis(): YAXisComponentOption {
      const fontSize = this.axisFontSize;
      return {
        name: "price",
        splitLine: { lineStyle: { type: "dashed" } },
        axisPointer: {
          label: { formatter: ({ value }) => Math.round(value) },
          fontSize,
        },
        axisLabel: { fontSize },
        max: this.maxPrice,
        min: 0,
        scale: true,
      };
    },
    tooltip(): EChartsOption["tooltip"] {
      return {
        showContent: true,
        trigger: "item",
        position: "left",
        axisPointer: { type: "shadow" },
        formatter: ({ data }: any) => `
        <p>台数:${d3.format(",")(data.total)}</p>
        <p>価格:${d3.format(",")(data.price)}</p>
        <p>排気量:${d3.format(",")(data.cc)}</p>
        `,
      };
    },
    datasets(): DatasetComponentOption[][] {
      return this.continuousYears.map<DatasetComponentOption[]>((year) =>
        this.maker.map<DatasetComponentOption>((maker) => ({
          source: Array.from(
            this.grouped.get(year)?.get(maker)?.values() ?? []
          ).filter(
            (v) =>
              Number(v.total) >= this.valueFilter[0] &&
              Number(v.total) <= this.valueFilter[1]
          ),
          dimensions: ["cc", "model", "price", "total"],
          id: maker,
        }))
      );
    },
    minValue(): number {
      return d3.min(this.data, (d) => Number(d.total));
    },
    maxValue(): number {
      return d3.max(this.data, (d) => Number(d.total));
    },
    dataZoom(): DataZoomComponentOption[] {
      const sliderZoom: DataZoomComponentOption[] = [
        {
          id: "sliderX",
          type: "slider",
          show: true,
          xAxisIndex: [0],
          showDataShadow: true,
          showDetail: true,
          start: this.xStart,
          end: this.xEnd,
        },
        {
          id: "sliderY",
          type: "slider",
          show: true,
          yAxisIndex: [0],
          left: "93%",
          start: this.yStart,
          end: this.yEnd,
          showDataShadow: true,
          showDetail: true,
        },
      ];
      if (this.isLabelDraggable) {
        return sliderZoom;
      }

      return sliderZoom.concat([
        {
          id: "insideX",
          type: "inside",
          xAxisIndex: [0],
          start: this.xStart,
          showDataShadow: true,
          showDetail: true,
          end: this.xEnd,
        },
        {
          id: "insideY",
          type: "inside",
          yAxisIndex: [0],
          start: this.yStart,
          end: this.yEnd,
          showDataShadow: true,
          showDetail: true,
        },
      ]);
    },

    serieses(): ScatterSeriesOption[][] {
      const { symbolSizeFactor } = this;
      const fontSize = this.labelFontSize;
      return this.continuousYears.map<ScatterSeriesOption[]>((year) =>
        this.maker.map<ScatterSeriesOption>((maker) => ({
          name: maker,
          datasetId: maker,
          type: "scatter",
          symbolSize: ({ total }) => Math.sqrt(total) * symbolSizeFactor,
          label: {
            show: true,
            fontSize,
            formatter: ({ data }) => (data as Data).model,
            position: "top",
          },
          labelLine: { show: true },
          labelLayout: (label) => {
            return {
              x: label.labelRect.x,
              moveOverlap: "shiftY",
              draggable: true,
            };
          },
          itemStyle: { color: this.$store.getters["colors/makers"][maker] },
          emphasis: {
            focus: "self",
            itemStyle: { borderColor: "black" },
          },
          encode: {
            x: "cc",
            y: "price",
            itemName: "model",
            seriesName: year,
          },
        }))
      );
    },
    baseOption(): EChartsOption {
      const {
        timeline,
        legend,
        grid,
        xAxis,
        yAxis,
        serieses,
        datasets,
        tooltip,
        dataZoom,
      } = this;
      const series = serieses[0];
      const dataset = datasets[0];
      return {
        baseOption: {
          legend,
          grid,
          xAxis,
          yAxis,
          series,
          dataset,
          tooltip,
          timeline,
          dataZoom,
        },
        options: this.serieses.map(
          (series, i) => ({ series, dataset: datasets[i] } as EChartsOption)
        ),
      };
    },
  },
  watch: {
    maxValue: function (val: number) {
      if (this.valueFilter[1] !== val) {
        this.updateValueFilter([this.valueFilter[0], val]);
      }
    },
  },
});
