<template>
  <v-btn
    text
    @click="$emit('reset-colors-btn-click')"
    v-show="isShow"
    :class="btnClasses"
    :disabled="btnDisabled"
    class="green--text"
  >
    色リセット
    <v-icon class="ml-1">mdi-format-color-fill</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "BaseResetColorButton",
  props: {
    btnDisabled: {
      type: Boolean,
      default: false,
    },
    isShow: {
      type: Boolean,
      default: false,
    },
    btnClasses: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

