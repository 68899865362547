export function downloadGraph(element: any) {
  const imgUrl = element.getDataURL({
    type: "jpeg",
    pixelRatio: window.devicePixelRatio,
    backgroundColor: "#fff"
  });
  download(imgUrl);
}

export function downloadGraphFromSvg(ele: SVGSVGElement) {
         const svg = ele;
         const svgData = new XMLSerializer().serializeToString(svg);
         const canvas = document.createElement("canvas");
         canvas.width = svg.width.baseVal.value;
         canvas.height = svg.height.baseVal.value;

         const ctx = canvas.getContext("2d");
         const image = new Image();
         image.onload = function() {
           ctx.drawImage(image, 0, 0);
           const a = document.createElement("a");
           a.href = canvas.toDataURL("image/png");
           a.download = new Date().getTime().toString() + ".png";
           a.click();
         };
         image.src =
           "data:image/svg+xml;charset=utf-8;base64," +
           btoa(unescape(encodeURIComponent(svgData)));
       }

function download(imgUrl) {
  if (window.navigator.msSaveOrOpenBlob) {
    let bstr = atob(imgUrl.split(",")[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let blob = new Blob([u8arr]);
    window.navigator.msSaveOrOpenBlob(
      blob,
      new Date().getTime().toString() + ".jpg"
    );
  } else {
    let tempA = document.createElement("a");
    tempA.download = new Date().getTime().toString() + ".jpg";
    tempA.href = imgUrl;
    document.body.appendChild(tempA);
    tempA.click();
    tempA.remove();
  }
}
