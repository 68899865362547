





















/* eslint-disable no-unused-vars */
import Vue, { PropOptions } from "vue";
import * as d3 from "d3";
import { Data } from "@/assets/types/Data";
import {
  EChartsOption,
  YAXisComponentOption,
  XAXisComponentOption,
  LegendComponentOption,
  SeriesOption,
  PieSeriesOption,
  LineSeriesOption,
} from "echarts";
import { isEmpty } from "@/js/utils";

export default Vue.extend({
  props: {
    lineData: { type: Array } as PropOptions<Data.Formatted[]>,
    year: { type: Array } as PropOptions<number[]>,
    maker: { type: Array } as PropOptions<string[]>,
    w_category: { type: Array } as PropOptions<string[]>,
  },
  data: () => ({
    selected: {},
    seriesKeys: ["year", "date", "maker", "w_category", "model"] as (keyof Data.Formatted)[],
    selectedSeries: "maker" as keyof Data.Formatted | null,
    xAxisKeys: ["year", "date", "maker", "w_category", "model"] as (keyof Data.Formatted)[],
    selectedXAxis: "date" as keyof Data.Formatted | null,
    valueType: ["cumulative", "normal"],
    selectedValueType: "normal",
    stack: false,
    graphType: ["line", "bar"] as SeriesOption["type"][],
    selectedGraphType: "line",
    focusedXValue: null,
    rotate: false,
  }),
  mounted() {
    this.updateSelected();
  },
  methods: {
    updateAxisPointer(event) {
      const value = event?.axesInfo?.[0]?.value;
      this.focusedXValue = value !== undefined ? String(this.xAxis.data[value]) : null;
    },

    isSelected(data: Data.Formatted) {
      return this.xAxisKeys.every((key) => this.selected[key]?.[data[key]] !== false);
    },
    dateFormat(d: number): string {
      const date = new Date(d);
      return date.getFullYear() + "-" + (date.getMonth() + 1);
    },
    updateSelected() {
      const legend = (this.$refs.chart as any)?.getOption()?.legend;
      this.selected[this.selectedSeries] = legend?.[0]?.selected ?? {};
      this.selected = JSON.parse(JSON.stringify(this.selected));
    },
    getCumulative(values: Map<number, number>, key: number) {
      let sum = 0;
      for (let i = 0; i < 12; i += 1) {
        const date = new Date(key);
        const pre = date.setMonth(date.getMonth() - i);
        const value = values.get(+pre) ?? 0;
        sum += value;
      }
      return sum;
    },
    format(xAxisValue: string | number) {
      if (this.selectedXAxis === "date") {
        return this.dateFormat(xAxisValue as number);
      }
      return String(xAxisValue);
    },
  },

  computed: {
    colors(): (name: string) => string {
      if (this.selectedSeries === "w_category") {
        return this.$store.getters["colors/wCategories"];
      } else if (this.selectedSeries === "maker") {
        return this.$store.getters["colors/makers"];
      } else if (this.selectedSeries === "model") {
        return this.$store.getters["colors/models"];
      } else {
        return null;
      }
    },
    groupBy(): (series: keyof Data) => Map<string | number, Map<number | string, number>> {
      const conv = (v) => (typeof v !== "string" ? +v : v);
      return (series: keyof Data) =>
        d3.rollup(
          this.lineData,
          (v) => d3.sum(v, (d) => (this.isSelected(d) ? d.value : 0)),
          (d) => conv(d[series]),
          (d) => conv(d[this.selectedXAxis]),
        );
    },

    // eslint-disable-next-line no-unused-vars
    value(): (values: any) => [string, number][] {
      return (values) =>
        Array.from(values).map(([d, value]) => [
          d,
          this.selectedValueType === "cumulative" ? this.getCumulative(values as any, d as number) : value,
        ]);
    },

    dataset(): [string, number, string][][] {
      const array = Array.from(this.groupBy(this.selectedSeries as any)).map(([series, values]) =>
        this.value(values)
          .map((v) => v.concat([series]) as any)
          .map(([a, b, c]) => [
            this.selectedXAxis === "date" ? this.dateFormat(a) : String(a),
            b,
            this.selectedSeries === "date" ? this.dateFormat(c) : String(c),
          ]),
      );
      const indexOf = (v) => this.xAxis.data.findIndex((d) => v[0] === d);
      return array.map((ar) => ar.sort((a, b) => indexOf(a) - indexOf(b))) as any;
    },

    pieSeries(): PieSeriesOption[] {
      const pieData = this.dataset
        .map(
          (values) =>
            d3.rollups(
              values.filter((v) => !this.focusedXValue || this.focusedXValue === v[0]),
              (v) => d3.sum(v, (d) => d[1]),
              (d) => d[2],
            )[0],
        )
        .filter((v) => v)
        .map<PieSeriesOption["data"][number]>(([name, value]) => ({
          name,
          value,
          itemStyle: { color: this.colors[name] },
        }));
      return [
        {
          tooltip: { trigger: "item" },
          type: "pie",
          id: "pie",
          radius: "30%",
          center: ["80%", "25%"],
          data: pieData as any,
          percentPrecision: 0.01,
          encode: {
            itemName: 0,
            value: 1,
            tooltip: [1],
          },
          name: this.focusedXValue ?? "ALL",
          label: {
            formatter: "{b}: {@1} ({d}%)",
          },
        },
      ];
    },

    // eslint-disable-next-line no-unused-vars
    series(): SeriesOption[] {
      return this.dataset.map<LineSeriesOption>((data) => {
        return {
          type: this.selectedGraphType as any,
          data,
          itemStyle: { color: this.colors[data[0][2]] },
          encode: this.rotate ? { y: 0, x: 1 } : { x: 0, y: 1 },
          name: data[0][2],
          stack: this.stack ? "total" : null,
        };
      });
    },

    grid: () => ({
      top: "55%",
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    }),

    yAxis(): XAXisComponentOption & YAXisComponentOption {
      const formatter = (v) => String(Math.round(Number(v) * 10));
      return {
        type: "value",
        tooltip: { show: true },
        axisLabel: { formatter },
        axisPointer: {
          label: { formatter: ({ value }) => formatter(value) },
        },
        axisLine: { lineStyle: { color: "red" } },
      };
    },

    dates(): string[] {
      if (isEmpty(this.year)) return [];
      const [min, max] = Array.from(this.year);
      const years = [];
      for (let year = min; year <= max; year += 1) {
        for (let month = 1; month <= 12; month += 1) {
          years.push(year + "-" + month);
        }
      }
      return years;
    },

    years(): string[] {
      if (isEmpty(this.year)) return [];
      const [min, max] = Array.from(this.year);
      const years = [];
      for (let year = min; year <= max; year += 1) {
        years.push(year);
      }
      return years;
    },

    xAxis(): XAXisComponentOption & YAXisComponentOption {
      const formatter = (v) => v.split("-")[0];
      if (this.selectedXAxis === "date") {
        return {
          type: "category",
          data: this.dates,
          axisLabel: { interval: 11, formatter, rotate: 30 },
        };
      } else if (this.selectedXAxis === "year") {
        return {
          type: "category",
          data: this.years,
        };
      }
      return {
        type: "category",
        data: this[this.selectedXAxis],
      };
    },

    // eslint-disable-next-line no-unused-vars
    legend(): LegendComponentOption {
      const key = this.selectedSeries;
      return {
        data: this[key],
        selected: this.selected[key],
      };
    },

    // eslint-disable-next-line no-unused-vars
    option(): (key: keyof Data) => EChartsOption {
      const { grid, xAxis, yAxis, series, pieSeries, legend } = this;
      return (key) => ({
        grid,
        xAxis: this.rotate ? yAxis : xAxis,
        yAxis: this.rotate ? xAxis : yAxis,

        series: series.concat(pieSeries),
        legend,
        tooltip: { trigger: "axis", axisPointer: { type: "cross" } },
      });
    },
  },
});
