






import Vue from 'vue'

export default Vue.extend({
  props: {
    showCc: { type: Boolean, required: true },
    showEv: { type: Boolean, required: true },
  },
  computed: {
    options(): { text: string; value: number }[] {
      return [
        { text: 'evを除いて表示', value: parseInt('01', 2) },
        { text: 'evのみを表示', value: parseInt('10', 2) },
        { text: 'evを含めて表示', value: parseInt('11', 2) },
      ]
    },
    selected: {
      get(): number {
        const { showCc, showEv } = this
        let value = 0
        showCc && (value = value + 1)
        showEv && (value = value + 2)
        return value
      },
      set(value: number) {
        let showCc = false
        let showEv = false
        if (value == 3) {
          showCc = true
          showEv = true
        } else if (value == 2) {
          showEv = true
        } else if (value == 1) {
          showCc = true
        }
        this.$emit('update:showCc', showCc)
        this.$emit('update:showEv', showEv)
      },
    },
  },
})
