<template>
  <draggable :list="labels" class="list-group" ghost-class="ghost">
    <div
      class="list-group-item"
      :style="{ 'background-color': element.color }"
      v-for="element in labels"
      :key="element.label"
    >
      {{ element.label }}
    </div>
  </draggable>
</template>
<script>
import draggable from "vuedraggable";
export default {
  name: "BarChartSort",
  props: {
    list: {
      type: Array,
    },
  },
  components: {
    draggable,
  },
  data: () => ({
    labels: [],
    inz: false,
  }),
  watch: {
    list() {
      this.inz = true;
      //初期化
      if (this.labels.length > 0) {
        this.labels.splice(0, this.labels.length);
      }
      this.labels.push(...this.list);
    },
    labels(newVal) {
      //最初はemitしない
      if (this.inz) {
        this.inz = false;
        return;
      }
      this.$emit(
        "sort-change",
        newVal.map((e) => e.label)
      );
    },
  },
  mounted() {
    this.inz = true;
    this.labels = this.list;
  },
};
</script>
<style scoped>
.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item {
  cursor: move;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>