




























import Vue, { PropOptions } from "vue";
import * as d3 from "d3";
import { Data } from "@/assets/types/Data";
import {
  EChartsOption,
  BarSeriesOption,
  YAXisComponentOption,
  XAXisComponentOption,
  LegendComponentOption,
} from "echarts";
import Resizable from "@/components/Resizable.vue";
import Tooltip from "./Tooltip.vue";
import SortBar from "./SortBar.vue";
import { dataToArray, downloadCsv } from "@/assets/downloadCsv";
import { downloadGraph } from "@/js/downloadGraph";

type YearsDomain<Domain extends { toString(): string } = string> = Iterable<Domain>;

export default Vue.extend({
  components: { SortBar, Resizable },
  data() {
    return {
      selectedModels: {},
      sorted: [],
    };
  },
  async mounted() {
    this.updateSelectedModels();
    this.updateMaxValue();
    await this.$nextTick();
    this.sorted = [...this.keys];
  },
  watch: {
    keys() {
      this.updateSelectedModels();
      this.updateMaxValue();
      this.sorted = [...this.keys];
    },
  },
  props: {
    maker: String,
    data: { type: Array, required: true } as PropOptions<Data[]>,
    years: { type: Array, required: true } as PropOptions<YearsDomain>,
    max: Number,
  },

  computed: {
    filteredData(): { value: number; year: string; model: string }[] {
      const { keys, data } = this;
      return data.map((d) => {
        const { total, year, model } = d;
        return {
          value: total,
          year: String(year),
          model: keys.includes(model) ? model : "OTHERS",
        };
      });
    },
    sums(): Map<string, Map<string, number>> {
      return d3.rollup(
        this.filteredData,
        (v) => d3.sum(v, (d) => d.value),
        (d) => d.year,
        (d) => d.model,
      );
    },
    series(): BarSeriesOption[] {
      const { continuousYears } = this;
      return d3
        .rollups(
          this.filteredData,
          (v) => d3.sum(v, (d) => d.value),
          (d) => d.model,
          (d) => d.year,
        )
        .map<BarSeriesOption>(([model, data]) => ({
          type: "bar",
          data,
          stack: "total",
          itemStyle: { color: this.$store.getters["colors/models"][model] },
          encode: { x: 0, y: 1 },
          name: model,
          animation: false,
        }))
        .sort(
          (a, b) =>
            this.sorted.findIndex((key) => key === a.name) -
            this.sorted.findIndex((key) => key === b.name),
        )
        .concat([
          {
            type: "bar",
            stack: "total",
            data: continuousYears.map((numYear) => {
              const year = String(numYear);
              return [year, 0];
            }),
            encode: { x: 0, y: 1 },
            name: "SUMLABEL",
            label: {
              show: true,
              formatter: ({ data }) => {
                const year = data[0];
                const value = (() => {
                  const sum = this.sums.get(year);
                  if (sum) {
                    const filteredSum = [...sum.entries()]
                      .filter((sum) => this.selectedModels?.[sum[0]] !== false)
                      .map((d) => d[1]);
                    return d3.sum(filteredSum);
                  } else {
                    return 0;
                  }
                })();
                return Math.round(
                  this.$store.getters["viewSettings/formatter"](value),
                ).toLocaleString();
              },
              fontSize: this.$store.getters["viewSettings/labelFontSize"],
              color: "black",
              position: "top",
            },
          },
        ]);
    },

    // "models"
    keys(): string[] {
      const { data } = this;
      const temp = d3
        .rollups(
          data,
          (v) => d3.sum(v, (d) => d.total),
          ({ model }) => model,
        )
        .sort((a, b) => b[1] - a[1]);
      const upper = temp.slice(0, Math.min(temp.length, 8)).map((d) => d[0]);
      if (upper.length < 8) {
        return upper;
      }
      return [...upper, "OTHERS"];
    },

    keyAndColorPair(): { key: string; color: string }[] {
      return this.keys.map((key) => {
        const selected = this.selectedModels?.[key] !== false;
        const color = selected
          ? this.$store.getters["colors/models"][key] ?? "rgb(89, 114, 192)"
          : "rgb(204,204,204)";

        return { key, color };
      });
    },

    xAxis(): XAXisComponentOption {
      return {
        type: "category",
        data: this.continuousYears,
        axisLabel: { fontSize: this.$store.getters["viewSettings/axisFontSize"] },
      };
    },

    yAxis(): YAXisComponentOption {
      const { max } = this;
      return {
        name: `台(${this.$store.getters["viewSettings/selectedUnit"].value})`,
        nameTextStyle: {
          fontSize: this.$store.getters["viewSettings/axisFontSize"],
        },
        type: "value",
        tooltip: { show: true },
        axisLabel: {
          fontSize: this.$store.getters["viewSettings/axisFontSize"],
          showMaxLabel: false,
          formatter: this.$store.getters["viewSettings/formatter"],
        },
        max,
      };
    },

    // yearsが[min,max]なので使いやすいように連続した値に直す
    continuousYears(): number[] {
      const array = Array.from(this.years);
      const min = array[0];
      const max = array[1];
      const years = [];
      for (let i = min; i <= max; i += 1) {
        years.push(i);
      }
      return years;
    },

    legend(): LegendComponentOption {
      return {
        data: this.sorted,
        textStyle: { fontSize: 12 },
        selected: this.selectedModels,
      };
    },

    grid() {
      return {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      };
    },

    tooltip(): EChartsOption["tooltip"] {
      return {
        trigger: "axis",
        formatter: ((data, t, cb) => {
          const tooltip = new Tooltip();
          const filtered = data.filter((d) => d.seriesName !== "SUMLABEL").reverse();
          if (filtered.length === 0) {
            return "<div>No Data</div>";
          }
          tooltip.$props.data = filtered;
          tooltip.$mount(document.createElement("div"), true);
          this.$nextTick(() => {
            cb(t, tooltip.$el.innerHTML);
          });
          return "loading";
        }) as any,
      };
    },

    option(): EChartsOption {
      const { legend, grid, xAxis, yAxis, series, tooltip } = this;
      return { legend, grid, xAxis, yAxis, series, tooltip };
    },

    values(): (string | number)[][] {
      const columns = ["model", "year", "value"];
      const fuga = this.series
        .map((s) => {
          const model = s.name as string;
          return (s.data as [string, number][]).map((d) => [model, ...d]);
        })
        .flat();

      return [columns, ...fuga.filter(([model]) => this.legend.selected[model] !== false)];
    },
    rawValues(): (string | number)[][] {
      return dataToArray(this.data.filter((v) => this.legend.selected[v.model] !== false));
    },
  },
  methods: {
    downloadGraph() {
      downloadGraph(this.$refs.chart);
    },
    downloadCsv() {
      downloadCsv(this.values, "StackBarChart");
      downloadCsv(this.rawValues, "StackBarChart2");
    },
    updateSort(value) {
      this.sorted = [...value];
    },
    onLegendSelectChanged() {
      this.updateSelectedModels();
      this.updateMaxValue();
    },
    updateSelectedModels() {
      const chart = this.$refs.chart as any;
      const option = chart.getOption();
      if (option) {
        const { legend } = option;
        this.selectedModels = legend[0].selected;
      }
    },
    updateMaxValue() {
      const { filteredData } = this;
      const maxes = d3.rollups(
        filteredData,
        (v) => d3.sum(v, (d) => (this.selectedModels[d.model] !== false ? d.value : 0)),
        (d) => d.year,
      );
      const max = maxes.length > 0 ? d3.max(maxes, (d) => d[1]) : 0;
      if (max > 0) {
        this.$emit("update:max", max);
      }
    },
  },
});
