<template>
    <validation-provider 
      :mode="mode" 
      ref="provider" 
      :name="label" 
      :rules="rules" 
      v-slot="{ errors, valid }"
      :custom-messages="customMessages"
    >
      <slot :errors="errors" :valid="valid">
      </slot>
    </validation-provider>
</template>
<script>
export default {
  name: "ValidationBase",
  props: {
    label: {
      type: String,
      required: true,
    }, 
    rules: {
      default: "required",
    },
    mode: {
      type: [Function, String],
      default: "aggressive"
    },
    customMessages: {
      type: Object
    }
  }
};
</script>