

































































import Vue, { PropOptions } from "vue";
import * as d3 from "d3";
import { Data } from "@/assets/types/Data";
import {
  EChartsOption,
  BarSeriesOption,
  YAXisComponentOption,
  XAXisComponentOption,
} from "echarts";
import Resizable from "@/components/Resizable.vue";
import { dataToArray, downloadCsv } from "@/assets/downloadCsv";
import { downloadGraph } from "@/js/downloadGraph";

type ColorType = { id: "year" | "model" | "maker"; text: string };

export default Vue.extend({
  components: { Resizable },
  data() {
    return {
      isMultipleYears: false,
      selectedColorType: "year" as ColorType["id"],
      colorTypes: [
        { id: "year", text: "年" },
        { id: "model", text: "モデル" },
        { id: "maker", text: "メーカー" },
      ] as ColorType[],
      // これ以下の割合のモデルをOTHERSに入れる
      share: 0.01,
      // fontSize: 11,
      displayMakerNameInner: false,
      privateSelectedYear: null as number | null,
      privateSelectedYearRange: null as number[] | null,
      privateselectedWCategory: null as string | null,
      dontShowOthers: false,
      isStack: false,
    };
  },

  props: {
    maker: { type: Array, required: true } as PropOptions<string[]>,
    w_category: { type: Array, required: true } as PropOptions<string[]>,
    data: { type: Array, required: true } as PropOptions<Data[]>,
    years: { type: Array, required: true } as PropOptions<number[]>,
  },

  computed: {
    axisFontSize(): number {
      return this.$store.getters["viewSettings/axisFontSize"];
    },
    labelFontSize(): number {
      return this.$store.getters["viewSettings/labelFontSize"];
    },
    displayMakerName: {
      get(): boolean {
        return this.selectedColorType === "maker" || this.displayMakerNameInner;
      },
      set(value: boolean) {
        this.displayMakerNameInner = value;
      },
    },
    title(): string {
      if (this.isMultipleYears) {
        const { selectedYearRange } = this;
        const [min, max] = selectedYearRange;
        return `${max - min}年,${min}-${max},${this.selectedWCategory}`;
      } else {
        return `1年単位,${this.selectedYear},${this.selectedWCategory}`;
      }
    },
    selectedWCategory: {
      get(): string {
        return this.privateselectedWCategory ?? this.w_category[0];
      },
      set(value: string) {
        this.privateselectedWCategory = value;
      },
    },
    selectedYear: {
      get(): number {
        return this.privateSelectedYear ?? this.years[1];
      },
      set(value: number) {
        this.privateSelectedYear = value;
      },
    },
    selectedYearRange: {
      get(): number[] {
        return this.privateSelectedYearRange ?? [this.years[1] - 1, this.years[1]];
      },
      set(value: number[]) {
        this.privateSelectedYearRange = value;
      },
    },
    filteredData(): Data[] {
      const { data, validYears } = this;
      return data.filter(
        (d) => validYears.includes(d.year) && d.w_category === this.selectedWCategory,
      );
    },
    mappedData(): { value: number; name: string; model: string; year: number; maker: string }[] {
      const { keys, filteredData } = this;
      const data = filteredData.map((d) => ({ ...d, name: d.maker + "." + d.model }));
      return data
        .map(({ model, total, year, name, maker }) => ({
          year,
          name: keys.includes(this.displayMakerName ? name : model) ? name : "OTHERS",
          value: total,
          model: keys.includes(this.displayMakerName ? name : model) ? model : "OTHERS",
          maker,
        }))
        .filter((d) => !(this.dontShowOthers && d.model === "OTHERS"));
    },
    series(): BarSeriesOption[] {
      const fontSize = this.labelFontSize;
      const label = {
        show: true,
        position: "right",
        color: "black",
        fontSize,
        opacity: 1,
      } as const;
      if (this.selectedColorType === "model") {
        return d3
          .rollups(
            this.mappedData,
            (v) => d3.sum(v, (d) => d.value),
            (d) => d.year,
            (d) => (this.displayMakerName ? d.name : d.model),
          )
          .flatMap(([year, data]) => data.map((d) => [year, ...d]))
          .sort((a, b) => (b[0] as number) - (a[0] as number))
          .map<BarSeriesOption>((d) => {
            const [year, index, data] = d;
            const nameToModel = (name) =>
              name
                .split(".")
                .filter((_, i) => i > 0)
                .join(".");
            const name = this.displayMakerName ? index : undefined;
            const model = this.displayMakerName ? nameToModel(name) : index;

            const [minYear, maxYear] = this.selectedYearRange;
            const length = maxYear - minYear;
            const opacity = (Number(year) - minYear + 1) / (length + 1);

            return {
              type: "bar",
              data: [[index, data]],
              // barWidth: 100 / this.validYears.length + '%',
              stack: this.isStack ? "total" : String(-year),
              itemStyle: {
                color: this.$store.getters["colors/models"][model] ?? "black",
                opacity: this.isStack ? 1 : opacity,
              },
              label,
              encode: { x: 1, y: 0 },
              name: year,
              animation: false,
            };
          });
      } else if (this.selectedColorType === "maker") {
        return d3
          .groups(
            this.mappedData,
            (d) => d.year,
            (d) => d.name,
          )
          .flatMap(([year, data]) => data.map((d) => [year, ...d]))
          .sort((a, b) => (b[0] as number) - (a[0] as number))
          .map<BarSeriesOption>((d) => {
            const [year, index, data] = d;
            const { maker } = (data as { maker: string }[])[0];
            const sum = d3.sum(data as { value: number }[], (d) => d.value);

            const [minYear, maxYear] = this.selectedYearRange;
            const length = maxYear - minYear;
            const opacity = (Number(year) - minYear + 1) / (length + 1);
            const color = this.$store.getters["colors/makers"][maker] ?? "black";
            console.log(maker, color, sum);

            return {
              type: "bar",
              data: [[index, sum]],
              stack: this.isStack ? "total" : String(-year),
              itemStyle: {
                color,
                opacity: this.isStack ? 1 : opacity,
              },
              label,
              encode: { x: 1, y: 0 },
              name: year,
              animation: false,
            } as BarSeriesOption;
          });
      }
      const series = d3
        .rollups(
          this.mappedData,
          (v) => d3.sum(v, (d) => d.value),
          (d) => d.year,
          (d) => (this.displayMakerName ? d.name : d.model),
        )
        .sort((a, b) => b[0] - a[0])
        .map<BarSeriesOption>(([year, data]) => {
          return {
            type: "bar",
            data,
            stack: this.isStack ? "total" : undefined,
            // itemStyle: { color: this.$store.getters['colors/models'][model] ?? 'black' },
            encode: { x: 1, y: 0 },
            name: year,
            animation: false,
            label,
          };
        });

      return series;
    },

    temp(): [string, number][] {
      const data = this.filteredData;
      if (this.displayMakerName) {
        return d3
          .rollups(
            data,
            (v) => d3.sum(v, (d) => d.total),
            (d) => d.maker + "." + d.model,
          )
          .sort((a, b) => b[1] - a[1]);
      }
      return d3
        .rollups(
          data,
          (v) => d3.sum(v, (d) => d.total),
          (d) => d.model,
        )
        .sort((a, b) => b[1] - a[1]);
    },
    tempSum(): number {
      return d3.sum(this.temp, (d) => d[1]);
    },

    // "models"
    keys(): string[] {
      const { temp, tempSum } = this;
      const threshold = this.share * tempSum;
      const items = temp.filter((d) => d[1] > threshold).map((d) => d[0]);
      if (items.length === temp.length || this.dontShowOthers) {
        return items.reverse();
      }
      return [...items, "OTHERS"].reverse();
    },

    yAxis(): YAXisComponentOption {
      const fontSize = this.axisFontSize;
      return {
        type: "category",
        data: this.keys,
        axisLabel: { fontSize, interval: 0 },
        splitNumber: 0,
      };
    },

    xAxis(): XAXisComponentOption {
      const fontSize = this.axisFontSize;
      return {
        name: `台(${this.$store.getters["viewSettings/selectedUnit"].value})`,
        type: "value",
        tooltip: { show: true },
        axisLabel: {
          fontSize,
          showMaxLabel: false,
          formatter: this.$store.getters["viewSettings/formatter"],
        },
      };
    },

    validYears(): number[] {
      if (this.isMultipleYears) {
        const [min, max] = this.selectedYearRange;
        const array: number[] = [];
        for (let i = 0; i <= max - min; i += 1) {
          array.push(min + i);
        }
        return array;
      } else {
        return [this.selectedYear];
      }
    },

    grid() {
      return {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      };
    },
    tooltip(): EChartsOption["tooltip"] {
      return {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      };
    },
    option(): EChartsOption {
      const { grid, xAxis, yAxis, series, tooltip } = this;
      return { grid, xAxis, yAxis, series, tooltip };
    },

    values(): (string | number)[][] {
      const columns = ["year", "model", "value"];
      const fuga = this.series.flatMap((s) => s.data.map((d: any[]) => [s.name, ...d]));
      return [columns, ...fuga];
    },
    rawValues(): (string | number)[][] {
      return dataToArray(this.data);
    },
  },
  methods: {
    downloadGraph() {
      downloadGraph(this.$refs.chart);
    },
    downloadCsv() {
      downloadCsv(this.values, "RankingChart");
      downloadCsv(this.rawValues, "RankingChart2");
    },
  },
});
