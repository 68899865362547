<template>
  <v-checkbox
    :value="checkboxValue"
    :error="hasError"
    v-model="inputValue"
    v-on="$listeners"
    v-bind="$attrs"
    :class="classes"
  >
    <template v-slot:label>
      <slot :name="label" v-if="labelAction" />
      <div v-else>{{ label }}</div>
    </template>
  </v-checkbox>
</template>

<script>
export default {
  name: "BaseCheckbox",
  computed: {
    inputValue: {
      get() {
        return this.value;
      },

      set(val) {
        this.$emit("input", val);
      },
    },
    hasError() {
      return this.errorMessages && this.errorMessages.length > 0;
    },
  },
  props: {
    label: {
      type: String,
    },

    checkboxValue: {
      type: [String, Object],
      default: () => [],
    },

    value: {
      type: [ Array, String ],
      default: () => [],
    },

    classes: {
      type: String,
    },

    errorMessages: {
      type: [Array, String],
      default: () => [],
    },

    labelAction: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

