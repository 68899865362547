<template>
  <v-row justify="center" class="app-variable-modal-wrapper">
    <v-col cols="12" sm="10" md="8">
      <validation-observer ref="obs">
        <v-form>
          <v-card flat>
            <slot></slot>
            <v-card-text>
              <base-clear-button
                :is-show="showClear"
                v-on="$listeners"
              ></base-clear-button>
              <base-reset-button
                :is-show="showReset"
                v-on="$listeners"
              ></base-reset-button>
              <base-reset-colors-button
                :is-show="showResetColors"
                v-on="$listeners"
              ></base-reset-colors-button>
            </v-card-text>
          </v-card>
        </v-form>
      </validation-observer>
    </v-col>
  </v-row>
</template>
<script>
import BaseClearButton from "@/components/Base/BaseClearButton";
import BaseResetButton from "@/components/Base/BaseResetButton";
import BaseResetColorsButton from "@/components/Base/BaseResetColorsButton";
export default {
  name: "AppVariableModal",
  components: {
    BaseClearButton,
    BaseResetButton,
    BaseResetColorsButton,
  },
  props: {
    showClear: {
      type: Boolean,
      default: false,
    },
    showReset: {
      type: Boolean,
      default: false,
    },
    showResetColors: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    async validate() {
      return await this.$refs.obs.validate();
    },
  },
};
</script>
