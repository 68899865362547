<template>
  <validation-base
    ref="validator"
    :mode="mode"
    :label="label"
    :rules="rules"
    :custom-messages="customMessages"
    v-slot="{ errors, valid }"
  >
    <base-number-format-input
      :round="round"
      :format="format"
      :precision="precision"
      :classes="classes"
      :value="inputValue"
      @input="updateInput"
      :errors="errors"
      :vmodel-debounce="debounce"
      :disabled="disabled"
    >
    </base-number-format-input>
    <slot :errors="errors" :valid="valid"></slot>
  </validation-base>
</template>
<script>
import BaseNumberFormatInput from "@/components/Base/BaseNumberFormatInput";
import ValidationBase from "@/components/Validation/ValidationBase";

export default {
  name: "ValidationNumberInput",
  components: {
    BaseNumberFormatInput,
    ValidationBase,
  },
  data() {
    return {
      inputValue: this.value,
    };
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    rules: {
      default: "required",
    },
    value: {
      required: true,
    },
    debounce: {
      type: [String, Number],
    },
    mode: {
      type: [Function, String],
      default: "passive",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: String,
    },
    customMessages: {
      type: Object,
    },
    precision: {
      type: Number,
      default: 1,
    },
    round: {
      type: Boolean,
      default: true,
    },
    format: {
      type: Boolean,
      default: true,
    },
    stopEmitOnError: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    async updateInput(val) {
      if (this.stopEmitOnError) {
        this.$refs.validator.$refs.provider.syncValue(val);
        const { valid } = await this.$refs.validator.$refs.provider.validate();
        if (valid) {
          this.$emit("input", val);
        } else {
          this.inputValue = val;
        }
      } else {
        this.$emit("input", val);
      }
    },
  },
  watch: {
    value(newVal) {
      if (newVal !== this.inputValue) {
        this.inputValue = newVal;
        this.$refs.validator.$refs.provider.reset();
      }
    },
  },
};
</script>