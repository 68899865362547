<template>
  <v-row>
    <div :class="classes">
      <div
        :class="checkboxWraperClass"
        v-for="(ele, index) in checkboxData"
        :key="generateId(index)"
      >
        <base-checkbox
          :label-action="labelAction"
          :classes="baseClass"
          :disabled="disabled"
          :checkboxValue="ele"
          :label="ele.text || ele"
          :errorMessages="errorMessages"
          v-model="$vModelDebounce"
          :color="colors ? colors[ele] : 'primary'"
          @click="$emit('checkbox-click', { id: index, val: ele })"
        >
          <template :slot="ele.text || ele">
            <slot :name="ele.text || ele" />
          </template>
        </base-checkbox>
      </div>
      <slot></slot>
    </div>
    <!-- slot to merge additional checkbox -->
    <slot
      name="addition-checkbox"
      :selectedCheckboxes="cachedLocalValue"
      :updateSelectedCheckboxes="updateVModelDebounce"
    >
    </slot>
    <!-- cachedLocalValue && updateVModelDebounce is from  "@/mixins/v-model-debounce"-->
  </v-row>
</template>

<script>
import BaseCheckbox from "@/components/Base/BaseCheckbox";
import vModelDebounce from "@/mixins/v-model-debounce";
import { deepClone } from "@/js/utils";

export default {
  name: "BaseCheckboxes",
  components: { BaseCheckbox },
  mixins: [vModelDebounce],
  watch: {
    checkboxData() {
      // なぜか必要
      this.cachedLocalValue = deepClone(this.value);
    },
  },
  props: {
    colors: { type: Object, default: () => ({}) },
    checkboxData: {
      type: Array,
      default: () => [],
    },
    classes: {
      type: String,
    },
    checkboxWraperClass: {
      type: String,
    },

    errorMessages: {
      type: [Array, String],
      default: () => [],
    },
    baseClass: {
      type: String,
    },
    labelAction: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    generateId(prefix) {
      return `${prefix}_${this._uid}`; // _uid can be passed in as props or from computed
    },
  },
};
</script>
