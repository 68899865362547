import { render, staticRenderFns } from "./SortBar.vue?vue&type=template&id=4b784b44&scoped=true&"
import script from "./SortBar.vue?vue&type=script&lang=ts&"
export * from "./SortBar.vue?vue&type=script&lang=ts&"
import style0 from "./SortBar.vue?vue&type=style&index=0&id=4b784b44&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b784b44",
  null
  
)

export default component.exports