




























































































import Vue, { PropOptions } from "vue";
import AppVariableModal from "../Common/AppVariableModal.vue";
import BaseCheckboxes from "@/components/Base/BaseCheckboxes.vue";
import BaseCheckbox from "@/components/Base/BaseCheckbox.vue";
import ValidationNumberInput from "@/components/Validation/ValidationNumberInput.vue";
import { isObjectMatched } from "@/js/utils";
import { Checkboxes } from "@/assets/types/Common";

export default Vue.extend({
  name: "VariablePrices",
  components: {
    AppVariableModal,
    ValidationNumberInput,
    BaseCheckboxes,
    BaseCheckbox,
  },

  props: {
    value: { type: Array, required: true } as PropOptions<Checkboxes[]>,
  },

  data() {
    return {
      labelFrom: "下限",
      labelTo: "上限",
      dynamicValue: {
        from: 0,
        to: 9999,
        checked: false,
      },
    };
  },
  mounted() {
    this.resetDynamicValue();
  },

  computed: {
    min(): number {
      return this.$store.getters.priceRange[0];
    },
    max(): number {
      return this.$store.getters.priceRange[1];
    },
    options(): Checkboxes[] {
      return this.$store.getters["prices"];
    },
    fromRules(): string {
      return `lte:@${this.labelTo}`;
    },

    toRules(): string {
      return `max_value:${this.max}|gte:@${this.labelFrom}`;
    },

    disableDynamicValue(): boolean {
      return !this.dynamicValue.checked;
    },

    selected: {
      get(): Checkboxes[] {
        return this.value;
      },

      set(val: Checkboxes[]) {
        this.$emit("input", val);
      },
    },

    dynamicValueSlider: {
      get(): number[] {
        return [this.dynamicValue.from, this.dynamicValue.to];
      },

      set(val: number[]) {
        const [from, to] = val;
        this.dynamicValue.from = from;
        this.dynamicValue.to = to;
      },
    },
  },
  watch: {
    selected: {
      immediate: true,
      handler(val) {
        const dynamicValue = val.find((value) =>
          isObjectMatched(value, {
            checked: { $exists: true },
          }),
        );
        if (dynamicValue) {
          this.dynamicValue = dynamicValue;
        } else {
          this.resetDynamicValue();
        }
      },
    },
  },
  methods: {
    resetDynamicValue() {
      this.dynamicValue.from = this.min;
      this.dynamicValue.to = this.max;
      this.dynamicValue.checked = false;
    },
    clear() {
      this.dynamicValue.checked = false;
      this.selected = [];
    },
    reset() {
      this.resetDynamicValue();
      this.selected = this.options;
    },
    clickDynamicValue() {
      this.dynamicValue.checked = (this.$refs.dynamicCheckbox as Vue).$el.querySelector("input").checked;
    },
  },
});
