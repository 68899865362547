import { isFunction } from "@/js/utils";

const EVENT_TIMEOUT = Symbol('eventTimeout');

export default {
    data() {
        return {
            // use Symbol to create unique property name
            [EVENT_TIMEOUT]: {}
        }
    },
    methods: {
        $emitDebounce(event, cb, delay) { // cb must return the emit value
            const context = this;
            const later = function () {
                context.$data[EVENT_TIMEOUT][event] = null;
                const returnValue = isFunction(cb) ? cb.apply(context) : cb;
                context.$emit(event, returnValue);
            }
            clearTimeout(context.$data[EVENT_TIMEOUT][event]);
            context.$data[EVENT_TIMEOUT][event] = setTimeout(later, delay);
        }
    }
}