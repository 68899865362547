<template>
  <v-row class="text-center mt-4">
    <v-col cols="11">
      <resizable :first-height="chartHeight">
        <v-chart
          ref="barchart"
          :option="myOption"
          :style="{ width: '100%', height: '100%' }"
          :autoresize="true"
          @click="showLink"
          @dblclick="setCondition"
        ></v-chart>
      </resizable>
    </v-col>
    <v-col cols="1">
      <btn-icon
        v-bind.sync="vChartParams"
        @image-download="download"
        @csv-download="downloadCsv"
      ></btn-icon>
    </v-col>
    <chart-params
      :data="yearData"
      :labelAndColor="labelAndColor"
      v-bind.sync="vChartParams"
      :isFuture="query.future"
      @sort-change="setSort"
    ></chart-params>
    <base-links v-bind.sync="vLinkParams"></base-links>
  </v-row>
</template>
<script>
import moment from "moment";
import BtnIcon from "@/components/BarChart/BtnIcon";
import ChartParams from "@/components/BarChart/ChartParams";
import { createNamespacedHelpers } from "vuex";
import * as d3 from "d3";
import { use } from "echarts/core";
import { BarChart, PieChart, LineChart } from "echarts/charts";
import { downloadGraph } from "@/js/downloadGraph";
import BaseLinks from "@/components/Base/BaseLinks";
import { dataToArray, downloadCsv } from "@/assets/downloadCsv";
import Resizable from "@/components/Resizable.vue";
use([BarChart, PieChart, LineChart]);
const { mapGetters: colorGetters } = createNamespacedHelpers("colors");
const OTHERS = "OTHERS@";
export default {
  name: "BarChart",
  components: {
    BtnIcon,
    ChartParams,
    BaseLinks,
    Resizable,
  },
  props: {
    yearData: {
      type: Array,
    },
    data: {
      type: Array,
    },
    date: {
      type: Array,
    },
    year: {
      type: Array,
    },
    countries: {
      type: Array,
    },
    query: {
      type: Object,
    },
  },
  data: () => ({
    vChartParams: {
      selectedMaker: null,
      selectedWCategory: null,
      selectedLooks: null,
      aspect: 27,
      rate_others: 1,
      showLabel: true,
      showPiechart: true,
      isYear: true,
      isBar: true,
      isStack: true,
    },
    chartTitle: "",
    sortList: [],
    vLinkParams: {
      show: false,
      x: 0,
      y: 0,
      items: [],
    },
  }),
  watch: {
    query: {
      deep: true,
      handler() {
        this.query.future && (this.vChartParams.isYear = true);
      },
    },
  },
  computed: {
    ...colorGetters({
      color_makers: "makers",
      color_w_categories: "wCategories",
      color_models: "models",
    }),
    category() {
      return this.query.future ? "f_category" : "w_category";
    },
    links() {
      return this.$store.getters["links/data"];
    },
    selectedKey() {
      if (
        this.vChartParams.selectedMaker ||
        this.vChartParams.selectedLooks === "model"
      ) {
        return "model";
      }
      if (
        this.vChartParams.selectedWCategory ||
        this.vChartParams.selectedLooks === "maker"
      ) {
        return "maker";
      }
      return this.category;
    },
    colors() {
      switch (this.selectedKey) {
        case "model":
          return this.color_models;
        case "maker":
          return this.color_makers;
      }
      return this.color_w_categories;
    },
    dates() {
      if (!this.date) {
        return this.years;
      }
      const [min, max] = Array.from(this.date);
      let now = moment(min + "-01");
      const goal = moment(max + "-01");
      const months = [];
      do {
        if (this.vChartParams.isYear) {
          if (this.query.year) {
            months.push(
              ...this.selectedYearsFuture.filter(
                (e) => e.toString().indexOf(now.format("YYYY")) > -1
              )
            );
          } else {
            months.push(now.format("YYYY"));
          }
          now = now.add(1, "year");
        } else {
          months.push(now.format("YYYY-MM"));
          now = now.add(1, "months");
        }
      } while (goal >= now);
      return months;
    },
    selectedYears() {
      if (!this.year) return [];
      const [min, max] = Array.from(this.year);
      const years = [];
      for (let year = min; year <= max; year += 1) {
        years.push(year);
      }
      return years;
    },
    selectedYearsFuture() {
      if (!this.year) return [];
      const [min, max] = Array.from(this.year);
      const years = [];
      for (let year = min; year <= max; year += 1) {
        if (this.yearData.some((e) => !e.future && e.year === year)) {
          // years.push(year + "実績");
          years.push(year);
        }
        if (this.yearData.some((e) => e.future && e.year === year)) {
          years.push(year + "予測");
        }
      }
      return years;
    },
    years() {
      if (this.vChartParams.isYear) {
        return this.query.future
          ? this.selectedYearsFuture
          : this.selectedYears;
      }
      if (!this.year) return [];
      const [min, max] = Array.from(this.year);
      const years = [];
      for (let year = min; year <= max; year += 1) {
        for (let month = 1; month <= 12; month += 1) {
          years.push(year + "-" + ("0" + month.toString()).substr(-2));
        }
      }
      return years;
    },
    //元データからフィルターを通した変数を返す。
    mapData() {
      if (this.vChartParams.isYear) {
        const yearData = JSON.parse(JSON.stringify(this.yearData));
        return yearData.filter((e) => {
          if (this.filteredVal(e) <= 0) {
            return false;
          }
          e.xAxisLabel = e.future ? e.year + "予測" : e.year.toString();
          return true;
        });
      }
      return this.data.filter((e) => this.filteredVal(e) > 0);
    },
    //数の降順にKeyで分割された配列を返す
    dividedSum() {
      return d3
        .rollups(this.mapData, this.sumList, (d) => d[this.selectedKey])
        .sort(function (a, b) {
          return d3.descending(a[1], b[1]);
        });
    },
    sourcePieChart() {
      //0:name 1:value
      return this.dividedSum.map((e) => ({
        name: e[0],
        value: e[1],
        itemStyle: { color: this.colors[e[0]] },
      }));
    },
    dividedRanking() {
      const _all = this.sumList(this.mapData);
      //filter代わり _topにラベルだけを抽出
      const _top = this.dividedSum.reduce((labels, curentRecord) => {
        if (curentRecord[1] / _all > this.vChartParams.rate_others * 0.01) {
          labels.push(curentRecord[0]);
        }
        return labels;
      }, []);
      //数が違う = フィルターがかったものがある
      if (_top.length !== this.dividedSum.length) {
        //OTHERSが予測の場合最初からあるため、あるかどうかを確認してからPush
        if (!_top.includes(OTHERS)) {
          _top.push(OTHERS);
        }
      }
      return _top;
    },
    //コンポーネントに渡す並び替え用データ
    labelAndColor() {
      return this.dividedRanking.map((e) => ({
        label: e,
        color: this.colors[e],
      }));
    },
    //並び替えが済んでいる配列
    sortedLabel() {
      //並び替えなし
      if (
        this.sortList.length === 0 ||
        this.sortList.length !== this.dividedRanking.length
      ) {
        return this.dividedRanking;
      }
      //たまたま数が一緒で並び替えなし
      const sortIdx = this.sortList.map((e) => this.dividedRanking.indexOf(e));
      if (sortIdx.includes(-1)) {
        return this.dividedRanking;
      }
      return this.sortList;
    },
    mySource() {
      //なぜかストリングじゃないとxAxisを制御できず
      const _mapCD = d3.group(
        this.mapData,
        (d) =>
          this.vChartParams.isYear
            ? this.query.future
              ? d.xAxisLabel
              : d.year.toString()
            : d.date.format("YYYY-MM"),
        (d) => d[this.selectedKey]
      );
      return this.calcSource(_mapCD);
    },
    mySeries() {
      const formatter = this.$store.getters["viewSettings/formatter"];
      const series = this.sortedLabel.map((e) => {
        return {
          type: this.vChartParams.isBar ? "bar" : "line",
          emphasis: {
            focus: "series",
          },
          stack: this.vChartParams.isStack,
          itemStyle: { color: this.colors[e] },
          label: {
            show: this.vChartParams.showLabel,
            fontSize: this.$store.getters["viewSettings/labelFontSize"],
            formatter: (ps) => {
              return (
                d3.format(",")(
                  Math.round(formatter(ps.value[ps.seriesIndex + 1]))
                ) +
                " " +
                d3.format(".0%")(
                  parseInt(ps.value[ps.seriesIndex + 1]) /
                    parseInt(ps.value[ps.value.length - 1])
                )
              );
            },
          },
        };
      });
      //stack label
      if (this.vChartParams.isStack) {
        series.push({
          type: this.vChartParams.isBar ? "bar" : "line",
          stack: true,
          label: {
            show: true,
            formatter: (ps) => {
              return d3.format(",")(
                Math.round(formatter(ps.value[ps.value.length - 1]))
              );
            },
            fontSize: this.$store.getters["viewSettings/labelFontSize"] + 2,
            color: "black",
            position: "top",
          },
        });
      }
      //piechart source
      series.push({
        name: "全体のサマリー",
        type: "pie",
        radius: "20%",
        center: ["90%", "15%"],
        tooltip: { trigger: "item" },
        data: this.vChartParams.showPiechart ? this.sourcePieChart : [],
        visible: false,
        z: 100,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      });
      return series;
    },
    myOption() {
      return {
        tooltip: {
          formatter: function (params) {
            if (params instanceof Array) {
              if (!params.length) {
                return null;
              }
              return params.reduce((message, param) => {
                if (param.seriesName !== "SUMLABEL") {
                  message += `<br/>${param.marker}${
                    param.seriesName
                  }: ${d3.format(",")(param.value[param.seriesIndex + 1])}`;
                }
                return message;
              }, `${params[0].axisValueLabel}`);
            } else {
              if (params.componentSubType === "pie") {
                return (
                  params.marker +
                  params.data.name +
                  " " +
                  d3.format(",")(params.data.value)
                );
              }
            }
          },
        },
        grid: {
          tooltip: {
            trigger: "axis",
          },
          right: this.vChartParams.showPiechart ? "20%" : "10%",
        },
        dataset: [
          {
            source: this.mySource,
          },
        ],
        xAxis: {
          type: "category",
          data: this.dates,
          axisLabel: {
            fontSize: this.$store.getters["viewSettings/axisFontSize"],
          },
        },
        yAxis: {
          name: `台(${this.$store.getters["viewSettings/selectedUnit"].value})`,
          axisLabel: {
            fontSize: this.$store.getters["viewSettings/axisFontSize"],
            formatter: this.$store.getters["viewSettings/formatter"],
          },
        },
        dataZoom: [
          {
            type: "inside",
            start: this.vChartParams.isYear ? (this.query.future ? 30 : 0) : 90,
            end: 100,
          },
          {
            type: "slider",
          },
        ],
        legend: {
          data: this.sortedLabel,
          left: 200,
          width: "70%",
          // type: "scroll",
        },
        title: {
          text: this.chartTitle,
        },
        series: this.mySeries,
      };
    },
    chartHeight() {
      let height = window.outerWidth * this.vChartParams.aspect * 0.01;
      return height + "px";
    },
  },
  mounted() {
    if (this.query.future) {
      this.vChartParams.isYear = this.query.future;
    }
  },
  methods: {
    //sort update handle
    setSort(labels) {
      //データ初期化
      if (this.sortList.length > 0) {
        this.sortList.splice(0, this.sortList.length);
      }
      this.sortList.push(...labels);
    },
    calcSource(cd) {
      //タイトル設定 良い場所がわからなかった。
      this.chartTitle = this.countries.reduce((acc, cur) => acc + "," + cur);
      let sources = this.getAllSource(cd);
      //日付順で並び替え
      sources = d3.sort(sources, function (a, b) {
        return d3.ascending(a[0], b[0]);
      });
      //頭にヘッダーをつける
      sources.unshift(["date", ...this.sortedLabel, "SUMLABEL", "TOTAL"]);
      return sources;
    },
    convertRecordtoArray(record, other_sum) {
      //sortedLabelで並び替え
      return this.sortedLabel.map((dividedField) => {
        if (dividedField === OTHERS) {
          return other_sum;
        }
        if (record[1].get(dividedField)) {
          return this.sumList(record[1].get(dividedField));
        }
        return 0;
      });
    },
    getAllSource(cd) {
      return [...cd].map((e) => {
        let _others = 0;
        if (this.dividedRanking.includes(OTHERS)) {
          e[1].forEach((val, key) => {
            if (!this.dividedRanking.includes(key)) {
              _others += this.sumList(val);
            }
          });
        }
        //需要予測でOthersを足す
        if (this.query.future) {
          e[1].forEach((val, key) => {
            if (key === OTHERS) {
              _others += this.sumList(val);
            }
          });
        }
        const _tops = this.convertRecordtoArray(e, _others);
        return [e[0], ..._tops, 0, d3.sum(_tops)];
      });
    },
    //listの合計を出す
    sumList(list) {
      //isYearを外したかったが汚くなるのでやめた
      // return d3.sum(list, (d) =>
      //   ({}.propertyIsEnumerable.call(d, "total") ? d.total : d.value)
      // );
      return d3.sum(list, (d) =>
        this.vChartParams.isYear ? d.total : d.value
      );
    },
    filteredVal(item) {
      //isYearを外したかったが汚くなるのでやめた
      const val = this.vChartParams.isYear ? item.total : item.value;
      return (!this.vChartParams.selectedWCategory ||
        this.vChartParams.selectedWCategory === "" ||
        this.vChartParams.selectedWCategory.includes(item[this.category])) &&
        (!this.vChartParams.selectedMaker ||
          this.vChartParams.selectedMaker === "" ||
          this.vChartParams.selectedMaker.includes(item.maker)) &&
        this.selectedYears.includes(item.year)
        ? val
        : 0;
    },
    async download() {
      const tempChartTitle = this.chartTitle;
      this.chartTitle = "";
      await this.$nextTick();
      downloadGraph(this.$refs.barchart);
      this.chartTitle = tempChartTitle;
    },
    //dblclickでドリルダウン
    setCondition(params) {
      if (this.selectedKey === "model") {
        return;
      }
      if (this.selectedKey === this.category) {
        this.vChartParams.selectedWCategory = params.seriesName;
        return;
      }
      if (this.selectedKey === "maker") {
        this.vChartParams.selectedMaker = params.seriesName;
      }
    },
    showLink(e) {
      const { data, event, seriesName } = e;
      const years = data[0].split("-");
      if (this.selectedKey !== "model") {
        return;
      }
      this.vLinkParams.items = this.links.filter(
        (e) => e.model === seriesName && e.year === years[0]
      );
      if (this.vLinkParams.items.length <= 0) {
        return;
      }
      event.event.preventDefault();
      this.vLinkParams.x = event.event.clientX;
      this.vLinkParams.y = event.event.clientY;
      this.$nextTick(() => {
        this.vLinkParams.show = true;
      });
    },
    getRawValues() {
      const query = Object.assign({}, this.query);
      if (this.vChartParams.selectedWCategory) {
        query.w_category = this.vChartParams.selectedWCategory;
      }
      if (this.vChartParams.selectedMaker) {
        query.maker = this.vChartParams.selectedMaker;
      }
      return dataToArray(this.$store.getters["data"](query));
    },
    downloadCsv() {
      downloadCsv(this.mySource, "BarChart");
      downloadCsv(this.getRawValues(), "BarChart2");
    },
  },
};
</script>
