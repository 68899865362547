





























































































































































































































































import Vue, { PropOptions } from 'vue'
import VarDates from '@/components/Variables/VariableDates.vue'
import VarCountries from '@/components/Variables/VariableCountries.vue'
import VarWCategories from '@/components/Variables/VariableWCategories.vue'
import VarManufacturers from '@/components/Variables/VariableManufacturers.vue'
import VarPrices from '@/components/Variables/VariablePrices.vue'
import VarEngineCc from '@/components/Variables/VariableCc.vue'
import VarEngineEv from '@/components/Variables/VariableEv.vue'
import TableGraph from '@/components/TableGraph/Index.vue'
import { deepClone, isString } from '@/js/utils'
import ConfirmSearchConditions from '@/components/Variables/ConfirmSearchConditions.vue'
import { StoreQueryType } from '@/assets/types/StoreQueryType'
import { Checkboxes } from '@/assets/types/Common'
import { Data } from '@/assets/types/Data'
import VariableModelsModal from './VariableModelsModal.vue'
import { filterData } from '@/js/filterData'
import FilterMessage from '@/components/Variables/FilterMessage.vue'
import Tab from '@/components/Variables/Tab.vue'
import ShowNullSwitch from '@/components/Variables/ShowNullSwitch/index.vue'
import { variableTitle } from '@/js/const'

type FilterType = { type: string; values: string[] }

export default Vue.extend({
  name: 'Variables',
  components: {
    ConfirmSearchConditions,
    VarDates,
    VarCountries,
    VarWCategories,
    VarManufacturers,
    VarPrices,
    VarEngineCc,
    VarEngineEv,
    TableGraph,
    VariableModelsModal,
    FilterMessage,
    Tab,
    ShowNullSwitch,
  },
  data: () => ({
    dialogConfirmSearchConditions: false,
    editingModel: null,
  }),
  props: {
    tab: { type: [Number, String], required: true },
    options: { type: Object, required: true } as PropOptions<StoreQueryType>,
    maker: { type: Array, required: true } as PropOptions<string[]>,
    model: { type: Array, required: true } as PropOptions<string[]>,
    year: { type: Array, required: true } as PropOptions<number[]>,
    date: { type: Array, required: true } as PropOptions<string[]>,
    cc: { type: Array, required: true } as PropOptions<Checkboxes[]>,
    kw: { type: Array, required: true } as PropOptions<string[]>,
    w_category: { type: Array, required: true } as PropOptions<string[]>,
    price: { type: Array, required: true } as PropOptions<Checkboxes[]>,
    showPriceNull: { type: Boolean, required: true },
    showCcNull: { type: Boolean, required: true },
    countries: { type: Array, required: true } as PropOptions<string[]>,
    countriesChanged: { type: Boolean, required: true },
    showCc: { type: Boolean, required: true },
    showEv: { type: Boolean, required: true },
    future: { type: Boolean, default: false },

    show: { type: Boolean, default: false },
    loadingCountryData: { type: Boolean, required: true },
    makerColors: { type: Object, required: true },
    wCategoryColors: { type: Object, required: true },
    modelColors: { type: Object, required: true },
    modelApplyMakerColor: { type: Boolean, required: true },
  },
  computed: {
    variables() {
      return variableTitle
    },
    filters(): { [key: string]: FilterType } {
      const convCheckBox = (array: Checkboxes[]) =>
        array.filter((v) => v.checked !== false).map((v) => (v.from ?? '') + '~' + (v.to ?? ''))
      return {
        maker: { type: 'mfr.', values: this.maker },
        w_category: { type: 'category', values: this.w_category },
        cc: { type: 'cc', values: convCheckBox(this.cc) },
        price: { type: 'price', values: convCheckBox(this.price) },
        date: { type: 'date', values: [this.date[0] + '-' + this.date[1]] },
      }
    },

    latestYear(): string {
      return this.date[1].split('-')[0]
    },

    latestMonth(): string {
      return this.date[1].split('-')[1]
    },

    makerSortOrder(): any {
      return this.$store.getters['sortedManufacturers']
        .map((name, i) => ({ [name]: i }))
        .reduce((pre, cur) => ({ ...pre, ...cur }), {})
    },

    wCategorySortOrder(): any {
      return this.$store.getters['sortedWCategories']
        .map((name, i) => ({ [name]: i }))
        .reduce((pre, cur) => ({ ...pre, ...cur }), {})
    },

    dataOriginal(): Data[] {
      return this.$store.state.data
    },

    baseData(): Data[] {
      const { cc, showCcNull, price, showPriceNull } = this
      return filterData(this.dataOriginal, { cc, showCcNull, price, showPriceNull }, this.options)
    },

    showTableGraphData(): boolean {
      return this.$store.getters['viewSettings/showTableGraph']
    },

    // この辺は一個ずつしか変わらないので差分でフィルタを取れる
    manufacturersGraphData(): Data[] {
      const { model, w_category } = this
      return filterData(this.baseData, { model, w_category }, this.options)
    },

    wCategoriesGraphData(): Data[] {
      const { model, maker } = this
      return filterData(this.baseData, { model, maker }, this.options)
    },

    modelGraphData(): Data[] {
      const { w_category, maker } = this
      return filterData(this.baseData, { w_category, maker }, this.options)
    },

    loadedCountries(): string[] {
      return this.$store.getters['query'].countries
    },

    showMonth: {
      get(): boolean {
        return this.$store.getters.showMonth
      },
      set(val: boolean) {
        this.$store.dispatch('updateShowMonth', val)
      },
    },

    queryDateFormat(): string {
      return this.showMonth ? 'month' : 'year'
    },

    formattedQueryDialogue(): StoreQueryType {
      const {
        maker,
        model,
        year,
        date,
        cc,
        kw,
        w_category,
        price,
        showPriceNull,
        showCcNull,
        countries,
      } = this
      const query = {
        maker,
        model,
        year,
        date,
        cc,
        kw,
        w_category,
        price,
        showPriceNull,
        showCcNull,
        countries,
      }

      let formattedQuery = deepClone(query)
      formattedQuery.date && (formattedQuery.date = formattedQuery.date.map(String))
      if (formattedQuery.price) {
        formattedQuery.price.map((e) => {
          if (!e.text || !isString(e.text)) {
            e.text = (e.from || 0) + '~' + (e.to || 0)
          }
        })
      }
      if (formattedQuery.cc) {
        formattedQuery.cc.map((e) => {
          if (!e.text || !isString(e.text)) {
            e.text = (e.from || 0) + '~' + (e.to || 0) + 'cc'
          }
        })
      }
      if (!formattedQuery.colors) formattedQuery.colors = {}
      const toArray = (data) => Object.entries(data).map(([key, value]) => ({ key, value }))
      formattedQuery.colors.maker = toArray(this.makerColors)
      formattedQuery.colors['w_category'] = toArray(this.wCategoryColors)
      formattedQuery.colors.model = toArray(this.modelColors)
      return formattedQuery
    },
  },
  methods: {
    hideModal() {
      // revert back to the store value if exit modal
      // this.localQueryOption = deepClone(this.query);
      this.$emit('updateShow', false)
    },
    confirmSearchConditions() {
      this.dialogConfirmSearchConditions = true
    },
    search() {
      this.$emit('search')
    },
  },
})
